import React, { useEffect, useState } from 'react';
import style from './wishlist.module.css';
import HeroImage from '../../components/hero-image/HeroImage';
import Image from "../../assets/images/aboutUsHeroImage.png";
import BottomHeroImage from "../../assets/images/BottomAbooutUs.png";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import handleFetchErrors from '../../utils/handleFetchErrors';
import getFetchConfig from '../../utils/getFetchConfig';
import {API_BASE_URL, IMG_URL} from '../../config/config';
import { updateCartCount } from '../../utils/commonFunctions';

export default function Wishlistpage({setcartCount, cartWishCount, setCartWishCount, cartCount}) {
    const fetchConfig = getFetchConfig();
    const Wishlist = window.localStorage.getItem('Product');
    const WishlistProduct = Wishlist ? JSON.parse(Wishlist) : [];
    const tokenExists = window.localStorage.getItem("token")
   // const imageURL = `https://admin-anycarpart.go-demo.com`;
    // const appURL = `http://localhost:3003/api/v1`;
    const { t } = useTranslation();
    const [wishlist,setwishlist]=useState([])



    const fetchwishlistProductHandler = () => {
        fetch(`${API_BASE_URL}/product/myFavoriteProduct`, {
            ...fetchConfig,
            method: `POST`,
        })
            .then(handleFetchErrors)
            .then((dataAllCartProduct) => {
                
                 setwishlist(dataAllCartProduct.data.myProducts)
                // WishlistProduct.push(dataAllCartProduct.data.myProducts)
            })
            .catch((error) => {
                console.log(error.message)
        });
    }
    

    const removeWishlsitHanlder = (id) => {
        if(tokenExists){
            fetch(`${API_BASE_URL}/product/remove/whishlist/${id}`, {
                ...fetchConfig,
                method: `delete`,
            })
                .then(handleFetchErrors)
                .then((dataAllCartProduct) => {
                    toast.success("remove product to whishlist")
                    //  window.location.reload()
                    setwishlist(prevWishlist => prevWishlist.filter(item => item._id !== id));
                    updateCartCount(setcartCount, setCartWishCount);

                })
                .catch((error) => {
                    console.log(error.message)
            });
        }else{
            const updatedWishlist = WishlistProduct.filter(item => item._id !== id);
            const newProduct = JSON.stringify(updatedWishlist)
            window.localStorage.setItem("Product", newProduct);
            // window.location.reload()
            setwishlist(prevWishlist => prevWishlist.filter(item => item._id !== id));
            updateCartCount(setcartCount, setCartWishCount);
        }
    }

    const addcartlishHandler = (product) => {
        if (tokenExists) {
            fetch(`${API_BASE_URL}/cart/updateCart`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    id: product._id,
                    quantity:1
                })
            })
                .then(handleFetchErrors)
                .then((dataupdateCartPrduct) => {
                    fetch(`${API_BASE_URL}/product/remove/whishlist/${product._id}`, {
                        ...fetchConfig,
                        method: `delete`,
                    })
                        .then(handleFetchErrors)    
                        .then((dataAllCartProduct) => {
                            //  window.location.reload()
                            setwishlist(prevWishlist => prevWishlist.filter(item => item._id !== product._id));
                    updateCartCount(setcartCount, setCartWishCount);

                        })
                        .catch((error) => {
                            console.log(error.message)
                    });
                    toast.success("product add to the cart")
                    // window.location.reload()
                    updateCartCount(setcartCount, setCartWishCount);

                })
            .catch((error)=>{
                console.log(error.message)
            });
        }else{
        const updatedWishlist = WishlistProduct.filter(item => item._id !== product._id);
        const newProduct = JSON.stringify(updatedWishlist)
        window.localStorage.setItem("Product", newProduct);

        let cart = window.localStorage.getItem("ProductCart");
        if (!cart) {
            product.quantity = 1;
            product.totalPrice = product.price;
            const newProduct = JSON.stringify([product]);
            window.localStorage.setItem("ProductCart", newProduct);
            toast.success("Product added to Cart.");
        } else {
            const existingProducts = JSON.parse(cart);
            let updated = false;

            for (let i = 0; i < existingProducts.length; i++) {
                
                if (existingProducts[i]._id === product._id) {

                    existingProducts[i].quantity += 1;
                    existingProducts[i].price = existingProducts[i].price + product.price;
                    updated = true;
                    break;
                }
            }

            if (!updated) {
                product.quantity = 1;
                product.price = product.price;
                existingProducts.push(product);
            }

            const newProduct = JSON.stringify(existingProducts);
            window.localStorage.setItem("ProductCart", newProduct);
            toast.success("Product added to Cart or quantity updated in Cart.");
        }
        // window.location.reload()
        updateCartCount(setcartCount, setCartWishCount);


    }
    }


    useEffect(() => {
        if (tokenExists) {
            fetchwishlistProductHandler()
        }
    }, [tokenExists])

    useEffect(() => {
     setwishlist(WishlistProduct)
    }, [])



    return (

        <>
            <HeroImage
                Heading={t('addpage')}
                HeroImage={Image} />
            <div className={style.main_wrapper}>
                <div className={style.wishlist_images_boxes}>
                    {wishlist?.length > 0 ? (
                        <>
                            {
                                wishlist.map((data) => {
                                    return (

                                        <div className={style.wishlist_image_box_category}>
                                            <div>
                                                <img src={`${IMG_URL}/${data.thumbnail}`} alt="" />
                                            </div>
                                            <div>
                                                <h2>{data.title}</h2>
                                                <h3>{data.sku}</h3>
                                                <p>{data.price} SAR</p>
                                            </div>
                                            <div className={style.wishlist_image_box_category_buttons}>
                                                <button onClick={() => { addcartlishHandler(data) }}>Add to cart</button>
                                                <button onClick={() => { removeWishlsitHanlder(data._id) }}>Remove form wish list</button>
                                            </div>
                                        </div>

                                    )
                                })

                            }
                        </>
                    ) : null}
                </div>
            </div>
        </>

    )
}
