import React, { useState, useEffect } from 'react';
import style from './profile.module.css';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import { API_BASE_URL, IMG_URL } from '../../config/config';
export default function Profile() {
    const navigate = useNavigate();
    // const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
    const appURL =`http://localhost:3003/api/v1`
	const imageURL = `https://admin-anycarpart.go-demo.com`;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    // const [email, setEmail] = useState('');
    const fetchConfig = getFetchConfig();
    const fetchProfileData = () => {
        fetch(`${API_BASE_URL}/profile`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((dataprofile) => {

               
                setFirstName(dataprofile?.data?.user?.userInfo?.first_name);
                setLastName(dataprofile.data.user.userInfo.last_name);
                setPhoneNumber(dataprofile.data.user.userInfo.phone_number);
                // setEmail(dataprofile.data.user.userInfo.email_address);
                // console.log(dataprofile.data.user.userInfo.first_name);


            })
            .catch((res)=>{

            });
    };
    const updateAPIData = () => {
        fetch(`${API_BASE_URL}/profile/update`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber
            })
        })
            .then(handleFetchErrors)
            .then((profileupdate) => {
                // console.log(`profileupdate`,profileupdate)

                if (profileupdate?.status == 200) {
                    toast.success(profileupdate?.data?.message);
                    navigate('/');

                } else if (profileupdate?.status == 403 || profileupdate?.status == 400) {
                    toast.error(profileupdate?.data?.message);
                }
            })
            .catch(standardFetchHandlers.error);
    }
    useEffect(() => {
         var x = localStorage.getItem("token");
    
        if (!x) navigate("/")
        if(x){
            fetchProfileData()
        }
       
    }, [])

 

    return (
        <>
            <div className={style.profile_container}>
                <div className={style.form_body}>
                    <div className={style.first_part}>
                        <div className={style.first_name_part}>
                            <h4>First Name</h4>
                            <input type="text" name="first_name" id="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className={style.last_name_part}>
                            <h4>Last Name</h4>
                            <input type="text" name="last_name" id="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div className={style.second_part}>
                        {/* <div className={style.email_part}>
                            <h4>Email</h4>
                            <input type="text" name="email" id="email"  value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div> */}
                        <div className={style.phone_no_part}>
                            <h4>Phone Number</h4>
                            <input type="text" name="phone_no" id="phone_no" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                        </div>
                    </div>
                    {/* <div className={style.thirt_part}>
                        
                        <div className={style.gender_part}>

                            <div className={style.male_part}>
                                <h4>Male</h4>
                                <input type="radio" name="male" id="male" className="gender"  />
                            </div>
                            <div className={style.female_part}>
                                <h4>Female</h4>
                                <input type="radio" name="female" id="female" className="gender"  />
                            </div>
                        </div>
                    </div> */}
                    <div className={style.four_part}>
                        <button onClick={updateAPIData}>Update</button>
                    </div>
                </div>
            </div>
        </>
    )
}
