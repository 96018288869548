import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Loader from '../../components/loader/Loader';
import ProductCard from '../../components/product-card/ProductCard';
import SubCategoriesCard from '../../components/product/subcategories-cards/SubCategoriesCard';
import LatestNewsCard from '../../components/latest-news-card/LatestNewsCard';
import Pagination from '../../components/paginations/Pagination';
import ServiceCard from '../../components/services-card/ServiceCard';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import style from './home.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid } from '@mui/material';
import './home.css'
import OilFilter from '../../assets/images/home/Pic (1).png';
import BatteryReplacement from '../../assets/images/home/batteryReplacement.png';
import ClutchTransition from '../../assets/images/home/Pic (3).png';
import ClutchTransition1 from '../../assets/images/home/Pic (4).png';
import OilFilter1 from '../../assets/images/home/Group 538.png';
import BatteryReplacement1 from '../../assets/images/home/Group 538 (1).png';
import LatestNewsImage1 from '../../assets/images/home/Rectangle 116.png'
import LatestNewsImage2 from '../../assets/images/home/Rectangle 116 (1).png';
import LatestNewsImage3 from '../../assets/images/home/Rectangle 116 (2).png';
import LatestNewsImage4 from '../../assets/images/home/Rectangle 116 (3).png';
import LatestImg from '../../assets/images/home/Rectangle 116.png'
import { API_BASE_URL, IMG_URL } from '../../config/config';
import NodDataImg from '../../assets/images/datanotfoundImg.png';
import { Diversity3 } from '@mui/icons-material';
import CustomPagination from '../../components/paginations/customPagination';
import { capitalizeWords } from '../../utils/commonFunctions';
import { ClipLoader } from 'react-spinners';
export default function Home(props) {
	const appURL = `http://localhost:3003/api/v1`
	// const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
	// const imageURL = `https://admin-anycarpart.go-demo.com`;
	const imageURL = `http://localhost:3003`;


	const { t } = useTranslation();
	const base_url = process.env.REACT_APP_BASE_URL;
	const serviceId1 = `6424081e366274f0fd36e88c`;
	const serviceId2 = `6426c6005b26b1b200b4712d`;
	const fetchConfig = getFetchConfig();
	const [sliderdata, setSliderData] = useState([]);

	const [productSubCategoriesByServiceId, setProductSubCategoriesByServiceId] = useState([]);
	const [productSubCategoriesByServiceId2, setProductSubCategoriesByServiceId2] = useState([]);
	const [productByServiceId, setProductByServiceId] = useState([]);
	const [productByServiceId2, setProductByServiceId2] = useState([]);
	const [promotionDiscount, setPromotionDiscount] = useState(null);

	const [productLimitSkip, setProductLimitSkip] = useState(0);
	const [paginationPage, setPaginationPage] = useState(``);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	}, []);

	const [productCategories, updateProductCategories] = useState([]),
		[productSubCategories, updateProductSubCategories] = useState([]),
		[brandsLists, updateBrandsLists] = useState([]),
		[productCategoriesId, updateIroductCategoriesId] = useState(``),
		[loader, updateLoader] = useState(false);

	

	const [selectedServiceId, updateSelectedServiceId] = useState(``);
	const [selectedServiceId2, updateSelectedServiceId2] = useState(``);
	const [cartWishCount, setcartWishCount] = useState(false);
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};


	const fetchSliderData = () => {
		fetch(`${API_BASE_URL}/slider`, {
			...fetchConfig,
			method: `GET`
		})
			.then(handleFetchErrors)
			.then((dataslider) => {
				setSliderData(dataslider.data.slider);
			})
			.catch(standardFetchHandlers.error);
	};

	const fetchPrductSubCategoriesByServiveId = () => {
		fetch(`${API_BASE_URL}/category/subcategory/${serviceId1}?type=service`, {
			...fetchConfig,
			method: `GET`
		})
			.then(handleFetchErrors)
			.then((dataSubcategory) => {

				updateSelectedServiceId2(dataSubcategory?.data.services[0]?._id)
				setProductSubCategoriesByServiceId(dataSubcategory.data.services)
			})
			.catch(standardFetchHandlers.error);
	};

	const fetchPrductSubCategoriesByServiveId2 = () => {
		fetch(`${API_BASE_URL}/category/subcategory/${serviceId2}?type=service`, {
			...fetchConfig,
			method: `GET`
		})
			.then(handleFetchErrors)
			.then((dataSubcategory) => {
				updateSelectedServiceId(dataSubcategory?.data.services[0]?._id)


				setProductSubCategoriesByServiceId2(dataSubcategory.data.services);
			})
			.catch(standardFetchHandlers.error);
	};
	// const productSubCatIdData = (id) => {

	// 	if (selectedServiceId2) {

	// 		fetch(`${API_BASE_URL}/product`, {
	// 			...fetchConfig,
	// 			method: `POST`,
	// 			body: JSON.stringify({
	// 				subcategoryId: selectedServiceId2,
	// 				limit: 4,
	// 				skip: productLimitSkip
	// 			})
	// 		})
	// 			.then(handleFetchErrors)
	// 			.then((productBySubcategory) => {
	// 				updateSelectedServiceId2(id)
	// 				setProductByServiceId(productBySubcategory.data.product)
	// 				setPaginationPage(productBySubcategory.data.pages)
	// 			})
	// 			.catch(standardFetchHandlers.error);
	// 	}
	// }
	const productSubCatIdData = (id) => {


		fetch(`${API_BASE_URL}/product/get-random-products`, {
			...fetchConfig,
			method: `GET`,

		})
			.then(handleFetchErrors)
			.then((productBySubcategory) => {
				// updateSelectedServiceId2(id)
				setProductByServiceId(productBySubcategory.data.products)
				
				// setPaginationPage(productBySubcategory.data.pages)
			})
			.catch(standardFetchHandlers.error);

	}
	// const productSubCatIdData2 = (id) => {
	// 	fetch(`${API_BASE_URL}/product`, {
	// 		...fetchConfig,
	// 		method: `POST`,
	// 		body: JSON.stringify({
	// 			subcategoryId: selectedServiceId,
	// 			limit: 4,
	// 			skip: productLimitSkip
	// 		})
	// 	})
	// 		.then(handleFetchErrors)
	// 		.then((productBySubcategory) => {
	// 			updateSelectedServiceId(id)

	// 			setProductByServiceId2(productBySubcategory.data.product)
	// 			setPaginationPage(productBySubcategory.data.pages)


	// 		})
	// 		.catch(standardFetchHandlers.error);

	// }
	const productSubCatIdData2 = (id) => {
		fetch(`${API_BASE_URL}/product/get-random-products`, {
			...fetchConfig,
			method: `GET`,

		})
			.then(handleFetchErrors)
			.then((productBySubcategory) => {
				setProductByServiceId2(productBySubcategory.data.products)
				// setPaginationPage(productBySubcategory.data.pages)
			})
			.catch(standardFetchHandlers.error);

	}


	const fetchProductCategories = () => {


		fetch(`${API_BASE_URL}/category`, {
			...fetchConfig,
			method: `GET`
		})
			.then(handleFetchErrors)
			.then((productCategories) => {
				const productCategoriesArr = productCategories?.data?.category;

				updateProductCategories(productCategoriesArr);
				updateIroductCategoriesId(productCategoriesArr[0]._id)
			})
			.catch(standardFetchHandlers.error);
	};
	const [imageError, setImageError] = useState(false);

	const handleImageError = () => {
		setImageError(true);
	};
	const fetchBrandLists = () => {

		fetch(`${API_BASE_URL}/brands`, {
			...fetchConfig,
			method: `GET`
		})
			.then(handleFetchErrors)
			.then((brandsLists) => {
				const brandsListsArr = brandsLists?.data?.brands;
				updateBrandsLists(brandsListsArr)

			})
			.catch(standardFetchHandlers.error);
	};

	const fetchPrductSubCategories = () => {

		if (productCategoriesId) {
			updateLoader(true)
			fetch(`${API_BASE_URL}/category/subcategory/${productCategoriesId}`, {
				...fetchConfig,
				method: `GET`
			})
				.then(handleFetchErrors)
				.then((productSubCategories) => {
					updateLoader(false)
					
					const productCategoriesArr = productSubCategories?.data?.subCategories;

					updateProductSubCategories(productCategoriesArr)


				})
				.catch(standardFetchHandlers.error);

		}

	}

	const handleForSetProductCategoryId = (productCategoryId) => {
		updateIroductCategoriesId(productCategoryId)
	}

	const handleForSetProductCategoryId2 = (id) => {
		
		updateSelectedServiceId2(id)
	}
	useEffect(() => {
		productSubCatIdData();
		fetchProductCategories()
		fetchPrductSubCategoriesByServiveId()
		fetchPrductSubCategoriesByServiveId2()
		fetchBrandLists();
		fetchSliderData();
		fetchProductCategories() ; 
		
	}, [])

	useEffect(() => {
		fetchPrductSubCategories()
	}, [productCategoriesId])

	useEffect(() => {
		if (selectedServiceId) productSubCatIdData2(selectedServiceId)
	}, [selectedServiceId])

	useEffect(() => {
		if (selectedServiceId2) productSubCatIdData(selectedServiceId2)
	}, [selectedServiceId2])

	useEffect(() => {
		productSubCatIdData()
		productSubCatIdData2()
		fetchSliderData()
		fetchBrandLists();
		fetchPrductSubCategoriesByServiveId()
		fetchPrductSubCategoriesByServiveId2()
		fetchProductCategories();
		fetchPrductSubCategories();
	}, [localStorage.getItem(`AnyCarPartLang`)])

	return (
		<>

			{loading ? (
				<Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
					<Grid item>
						<Box sx={{ display: 'flex' }}>
							{/* <CircularProgress /> */}
							<ClipLoader color="#212F63" size={50} />

						</Box>
					</Grid>
				</Grid>
			) : (


				<div className='banner-section-wrapper'>
					{/* <div style={{ display: 'flex', justifyContent: 'space-around', marginLeft: '5%', marginRight: '5%' }}>


						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
							className='icon_image'>
							<rect x="0.5" y="0.5" width="23" height="23" stroke="#F86B1F" />
							<g clip-path="url(#clip0_361_57718)">
								<path d="M5.25 6H18.75V7.5H5.25V6ZM5.25 11.25H18.75V12.75H5.25V11.25ZM5.25 16.5H18.75V18H5.25V16.5Z" fill="#F86B1F" />
							</g>
							<defs>
								<clipPath id="clip0_361_57718">
									<rect width="18" height="18" fill="white" transform="translate(3 3)" />
								</clipPath>
							</defs>
						</svg>
						<span className='dropdown_text'>	{t('HeaderProducts')}   </span>
						<span className='dropdown_text'>{t('HeaderDeals')}   </span>
						<span className='dropdown_text'>{t('HeaderCar')}   </span>
						<span className='dropdown_text'>{t('HeaderInterior')}   </span>
						<span className='dropdown_text'>  {t('HeaderExterior')}   </span>
						<span className='dropdown_text'>{t('HeaderLightning')}   </span>
						<span className='dropdown_text'>{t('HeaderTyres')}   </span>
						<span className='dropdown_text'>{t('HeaderBody')}   </span>
						<span className='dropdown_text'>{t('HeaderAudio')}   </span>
						<span className='dropdown_text'>{t('HeaderAutomotive')}   </span>
						<span className='dropdown_text'>{t('HeaderSpeciality')}   </span>




					</div> */}
					<Slider {...settings}>
						{
							sliderdata.map((allSliderData) => {
								return (
									<div>
										<section className="bg-image">
											<div className="heading-h1 arabic-heading" >
												<h1> {t(allSliderData.title)} </h1>
												<h2>{t(allSliderData.description)} </h2>
												<button className=" button button-1 learn-more-btn"> <a target="_blank" href={t(allSliderData.url)}>{t('LearnMore')}</a></button>
												{/* <Pagination classname={style.pagnation_color} />
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.129 5.25L5.106 1.227L6.1665 0.166504L12 6L6.1665 11.8335L5.106 10.773L9.129 6.75H0V5.25H9.129Z" fill="white" />
							</svg> */}
												{/* <Pagination classname={style.pagnation_color}
							isViewAllBtn={false}
							 /> */}
											</div>
											<div>
												{/* <img className="background-img" src={HeroImage} alt="" /> */}
												<img style={{ height: "600px", width: "auto", objectFit: "contain" }} className="background-img" src={`${IMG_URL}/${allSliderData?.image}`} alt="" />
												<div className='arabic-border-box'>
													<div className="border-box border-box1 ">
														<img src="/assets/Subtract.png" alt="" />
														<div className="border-line-text">
															<div className="call-us-img">
																<i class="fa-solid fa-phone phone"></i>
																<a href="#" />
																<a href="tel:555-555-5555" >

																</a>
															</div>
															<div className="call-us-text">
																<h3>{t('Callus24/7')}</h3>
																<p>{t('Phone')}</p>
															</div>
														</div>
														<div className="border-line-text1">
															<div className="call-us-img">
																<i className=" bounce-map fa-solid fa-location-dot"></i>
															</div>
															<div className="call-us-text">
																<h3>{t('WeareHere')}</h3>
																<p>{t('HomeText')}</p>
															</div>

														</div>

													</div>
												</div>
											</div>
										</section>
									</div>
								)
							})
						}
					</Slider>
					<section className={style.info_section_wrapper}>
						<aside >
							<div className={style.info_section_icon}>
								<svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M33.8824 0.0585938V10.6468H30.7059V4.29389H20.1176V10.6468H15.8824V4.29389H5.29412V10.6468H2.11765V0.0585938H0V33.9409H5.29412H15.8824H20.1176H30.7059H36V0.0585938H33.8824ZM22.2353 6.41154H24.3529V8.52918H26.4706V6.41154H28.5882V10.6468H22.2353V6.41154ZM7.41177 6.41154H9.52941V8.52918H11.6471V6.41154H13.7647V10.6468H7.41177V6.41154ZM5.29412 12.7645H15.8824H20.1176H30.7059H33.8824V21.2351H31.7647V14.8821H25.4118V21.2351H23.2941V14.8821H12.7059V21.2351H10.5882V14.8821H4.23529V21.2351H2.11765V12.7645H5.29412ZM29.6471 21.2351H27.5294V16.9998H29.6471V21.2351ZM19.0588 19.1174V16.9998H21.1765V21.2351H14.8235V16.9998H16.9412V19.1174H19.0588ZM8.47059 21.2351H6.35294V16.9998H8.47059V21.2351ZM7.41177 31.8233V27.588H9.52941V29.7057H11.6471V27.588H13.7647V31.8233H7.41177ZM22.2353 31.8233V27.588H24.3529V29.7057H26.4706V27.588H28.5882V31.8233H22.2353ZM30.7059 31.8233V25.4704H20.1176V31.8233H15.8824V25.4704H5.29412V31.8233H2.11765V23.3527H4.23529H10.5882H12.7059H23.2941H25.4118H31.7647H33.8824V31.8233H30.7059Z" fill="#F86B1F" />
								</svg>
							</div>
							<div className={`${style.info_section_content} ${style.arabic_info_section_content}`}>
								<h4>{t('c1Heading1')}</h4>
								<p >{t('c1Descritpion1')}</p>
							</div>
						</aside>
						<aside >
							<div className={style.info_section_icon}>
								<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M34.2422 13.5563H31.4812V12.3516C31.4812 5.46349 24.7622 0 18 0C11.2499 0 4.51877 5.45105 4.51877 12.3516V13.5563H1.75781C0.788555 13.5563 0 14.3448 0 15.3141V22.9453C0 23.9145 0.788555 24.7031 1.75781 24.7031H4.51898V26.917C4.51898 30.6797 7.57983 33.7409 11.3421 33.7409H14.1211V34.2422C14.1211 35.2114 14.9096 36 15.8789 36H20.1211C21.0904 36 21.8789 35.2114 21.8789 34.2422V31.1297C21.8789 30.1604 21.0904 29.3719 20.1211 29.3719H15.8789C14.9096 29.3719 14.1211 30.1604 14.1211 31.1297V31.6315H11.3421C8.74294 31.6315 6.62836 29.5166 6.62836 26.917V26.3976H9.38911C10.3584 26.3976 11.1469 25.6091 11.1469 24.6398V13.6195C11.1469 12.6503 10.3584 11.8617 9.38911 11.8617H6.64341C6.96002 6.69741 12.2302 2.10938 18 2.10938C23.7698 2.10938 29.04 6.69741 29.3567 11.8617H26.611C25.6417 11.8617 24.8531 12.6503 24.8531 13.6195V24.6398C24.8531 25.6091 25.6417 26.3976 26.611 26.3976H29.7235C30.6715 26.3976 31.4462 25.6431 31.4797 24.703H34.2422C35.2114 24.703 36 23.9145 36 22.9452V15.3141C36 14.3448 35.2114 13.5563 34.2422 13.5563ZM16.2304 31.4812H19.7696V33.8906H16.2304V31.4812ZM2.10938 15.6656H4.51877V22.5937H2.10938V15.6656ZM9.03748 24.2883H6.62836C6.62836 21.1905 6.62815 17.0918 6.62815 13.9712H9.03755V24.2883H9.03748ZM29.3719 24.2883H26.9625V13.9711H29.3719V24.2883ZM33.8906 22.5937H31.4812C31.4812 21.9472 31.4812 16.5922 31.4812 15.6656H33.8906V22.5937Z" fill="#F86B1F" />
								</svg>

							</div>
							<div className={`${style.info_section_content} ${style.arabic_info_section_content}`}>
								<h4>{t('c1Heading2')}</h4>
								<p>{t('c1Descritpion2')}</p>
							</div>
						</aside>
						<aside >
							<div className={style.info_section_icon}>
								<svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.9996 0C6.77728 0 0.0878906 6.68939 0.0878906 14.9117C0.0878906 18.8948 1.63896 22.6394 4.45542 25.4559L14.9996 36.0001L25.5438 25.4559C28.3602 22.6394 29.9113 18.8948 29.9113 14.9117C29.9113 6.68939 23.2219 0 14.9996 0V0ZM24.0521 23.9642L14.9996 33.0168L5.94707 23.9642C3.52902 21.5462 2.19733 18.3313 2.19733 14.9116C2.19733 7.85254 7.94042 2.10944 14.9996 2.10944C22.0588 2.10944 27.8019 7.85253 27.8019 14.9117C27.8019 18.3313 26.4702 21.5462 24.0521 23.9642V23.9642Z" fill="#F86B1F" />
									<path d="M14.9994 4.21875C9.10337 4.21875 4.30664 9.01548 4.30664 14.9115C4.30664 20.8076 9.10337 25.6043 14.9994 25.6043C20.8954 25.6043 25.6922 20.8075 25.6922 14.9115C25.6922 9.01548 20.8954 4.21875 14.9994 4.21875V4.21875ZM22.4448 10.6441H11.235V7.19897C12.3723 6.64165 13.6498 6.32819 14.9994 6.32819C18.1799 6.32819 20.962 8.06707 22.4448 10.6441ZM23.5828 14.9115C23.5828 15.6566 23.4872 16.3797 23.3079 17.0695H11.235V12.7535H23.3079C23.4872 13.4433 23.5828 14.1665 23.5828 14.9115ZM6.41608 14.9115C6.41608 12.4489 7.45885 10.2257 9.12559 8.65891V21.1641C7.45885 19.5974 6.41608 17.3741 6.41608 14.9115ZM14.9994 23.495C13.6498 23.495 12.3723 23.1815 11.235 22.6241V19.179H22.4448C20.962 21.756 18.1799 23.495 14.9994 23.495V23.495Z" fill="#F86B1F" />
								</svg>
							</div>
							<div className={`${style.info_section_content} ${style.arabic_info_section_content}`} >
								<h4>{t('c1Heading3')}</h4>
								<p>{t('c1Descritpion3')}</p>
							</div >
						</aside>
						<aside >
							<div className={style.info_section_icon}>
								<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="64" height="64" fill="#F7F7F7" />
									<path d="M25.2922 21.1444L23.8008 19.6528L20.3278 23.1258L18.9641 21.7622L17.4727 23.2538L20.3278 26.1089L25.2922 21.1444Z" fill="#F86B1F" />
									<path d="M37.3438 27.7812V21.4531H28.69C28.1766 17.8801 25.0956 15.125 21.3828 15.125C17.3119 15.125 14 18.4369 14 22.5078C14 24.5147 14.8054 26.3369 16.1094 27.669V42.5469H20.3281V46.7656H24.7283C25.1637 47.9932 26.336 48.875 27.7109 48.875C29.0859 48.875 30.2582 47.9932 30.6936 46.7656H39.6345C40.0699 47.9932 41.2422 48.875 42.6172 48.875C43.9921 48.875 45.1645 47.9932 45.5998 46.7656H50V32.6178L45.1634 27.7812H37.3438ZM21.3828 17.2344C24.2906 17.2344 26.6562 19.6 26.6562 22.5078C26.6562 25.4156 24.2906 27.7812 21.3828 27.7812C18.475 27.7812 16.1094 25.4156 16.1094 22.5078C16.1094 19.6 18.475 17.2344 21.3828 17.2344ZM18.2188 29.1773C19.1783 29.6343 20.2511 29.8906 21.3828 29.8906C25.0956 29.8906 28.1766 27.1356 28.69 23.5625H35.2344V40.4375H18.2188V29.1773ZM27.7109 46.7656C27.1294 46.7656 26.6562 46.2925 26.6562 45.7109C26.6562 45.1294 27.1294 44.6562 27.7109 44.6562C28.2925 44.6562 28.7656 45.1294 28.7656 45.7109C28.7656 46.2925 28.2925 46.7656 27.7109 46.7656ZM42.6172 46.7656C42.0356 46.7656 41.5625 46.2925 41.5625 45.7109C41.5625 45.1294 42.0356 44.6562 42.6172 44.6562C43.1987 44.6562 43.6719 45.1294 43.6719 45.7109C43.6719 46.2925 43.1987 46.7656 42.6172 46.7656ZM45.5998 44.6562C45.1645 43.4287 43.9921 42.5469 42.6172 42.5469C41.2422 42.5469 40.0699 43.4287 39.6345 44.6562H30.6936C30.2582 43.4287 29.0859 42.5469 27.7109 42.5469C26.336 42.5469 25.1637 43.4287 24.7283 44.6562H22.4375V42.5469H37.3438V29.8906H39.4531V38.3281H47.8906V40.4375H45.7812V42.5469H47.8906V44.6562H45.5998ZM47.8906 36.2188H41.5625V29.8906H44.2897L47.8906 33.4915V36.2188Z" fill="#F86B1F" />
								</svg>
							</div>
							<div className={`${style.info_section_content} ${style.arabic_info_section_content}`}>
								<h4>{t('c1Heading4')}</h4>
								<p>{t('c1Descritpion4')}</p>
							</div>
						</aside>
					</section>
					<section className={style.top_categgories_section_wrapper}>
						<p className={style.top_categgories_section_sub_heading}>{t('topCategoriesSubHeading')}</p>
						<h1 className={style.top_categgories_section_heading}>{t('topCategoriesHeading')}<br /> {t('topCategoriesHeading1')}</h1>
						<section className={style.top_categgories_card_section} >
							<div id='hhover'>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
									<path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
									<path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
									<path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
									<path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
									<path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
									<path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle1')}</p>
							</div>
							<div>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
									<path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
									<path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
									<path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
									<path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
									<path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
									<path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
									<path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
									<path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
									<path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
									<path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle2')}</p>
							</div>
							<div>
								<svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
									<path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
									<path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle3')}</p>
							</div>
							<div>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
									<path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
									<path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
									<path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
									<path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
									<path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
									<path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle4')}</p>
							</div>
							<div>
								<svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
									<path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
									<path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle5')}</p>
							</div>
							<div>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
									<path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
									<path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
									<path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
									<path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
									<path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
									<path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
									<path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
									<path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
									<path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
									<path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('topCategoriesCardSubHeading')}</p>
							</div>
							<div id='hhover'>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
									<path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
									<path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
									<path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
									<path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
									<path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
									<path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle1')}</p>
							</div>
							<div>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
									<path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
									<path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
									<path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
									<path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
									<path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
									<path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
									<path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
									<path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
									<path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
									<path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle2')}</p>
							</div>
							<div>
								<svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
									<path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
									<path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
								</svg>

								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle3')}</p>
							</div>
							<div>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
									<path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
									<path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
									<path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
									<path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
									<path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
									<path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle4')}</p>
							</div>
							<div>
								<svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
									<path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
									<path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('AboutUsSellerBenefitsTitle5')}</p>
							</div>
							<div>
								<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
									<path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
									<path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
									<path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
									<path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
									<path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
									<path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
									<path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
									<path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
									<path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
									<path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
								</svg>
								<p className='autoparts_text'>{t('topCategoriesCardSubHeading')}</p>
							</div>
						</section>
					</section>
					{/* service we offer */}
					<section className={style.service_section_wrapper}>
						<p>{t('ServicesWeOfferSubHeading')}</p>
						<h1>{t('ServicesWeOfferHeading')}</h1>

						<ul className={style.sevice_tabs} >
							<li className='service_text'>{t('ServicesWeOfferCategories')}</li>
							<li className='service_text'>{t('ServicesWeOfferCategories1')}</li>
							<li className='service_text'>{t('ServicesWeOfferCategories2')}</li>
							<li className='service_text'>{t('ServicesWeOfferCategories3')}</li>
							<li className='service_text'>{t('ServicesWeOfferCategories4')}</li>
						</ul>
						<main className={style.service_card_wrapper}>
							<ServiceCard imageSrc={OilFilter} textContent={"Change Oil & Filter"} />
							<ServiceCard imageSrc={BatteryReplacement} textContent={"Battery Replacement"} />
							<ServiceCard imageSrc={ClutchTransition} textContent={"Clutch & Transmission"} />
							<ServiceCard imageSrc={ClutchTransition1} textContent={"Clutch & Transmission"} />
							<ServiceCard imageSrc={OilFilter1} textContent={"Change Oil & Filter"} />
							<ServiceCard imageSrc={BatteryReplacement1} textContent={"Battery Replacement"} />
						</main>
					</section>
					{/* what we offer section */}
					<section className={style.our_product_section_wrapper}>
						<p>{t('ourProductSubHeading')}</p>
						<h1>{t('ourProductHeading')}</h1>
						<div className={style.our_product_section} >
							<div id className={style.our_product_tab_section}>
								<ul>
									{
										productCategories?.length ?
											productCategories.map((productCategory) => {
												
												return (
													<li className={style.product_categories_image_text} >
														{
														
														}
														{productCategory._id === productCategoriesId ?
															<img
																src={`${IMG_URL}/${productCategory.image && productCategory.image.length > 0 && productCategory?.image[0]}`}
																className={style.imgActive}
																style={{
																	filter: "brightness(1000%) grayscale(100%)  opacity(0.1)  drop-shadow(0 0 0 #f86b1f)  drop-shadow(0 0 0 #f86b1f)  drop-shadow(0 0 0 #f86b1f) drop-shadow(0 0 0 #f86b1f) drop-shadow(0 0 0 #f86b1f)"
																}}
																alt={productCategory?.title}
																onError={(e) => {
																	e.target.src = "../../assets/images/noimg-png-output.png";
																}}
															/>
															:
															<img
																src={productCategory.image && productCategory.image.length > 0 ? `${IMG_URL}/${productCategory.image[0]}` : '../../../assets/images/no-img-found.png'}
																alt={productCategory?.title}
																onError={(e) => {
																	e.target.src = "../../assets/images/noimg-png-output.png";
																}}
															/>
														}

														{/* <img src={`${imageURL}/${productCategory?.image}`} alt={productCategory?.title} /> */}

														<p className={productCategory._id === productCategoriesId ? `active` : ``}>
															<span onClick={() => { handleForSetProductCategoryId(productCategory?._id) }}>
																{productCategory?.title}
															</span>
														</p>

													</li>
												)
											})
											:
											<h3> Nothing to show </h3>
									}
								</ul>
							</div>
							<section className={style.our_product_card_section_wrapper}>
								<div className={`${style.our_product_card_section} ${!productSubCategories.length ? `nothing_to_show_text` : ``}`}>
									{
										productSubCategories.length ?
											productSubCategories.map((productSubCategory, i) => {
												
												return (
													<SubCategoriesCard key={i}
														productCount={productSubCategory.productCount}
														categoryId={productSubCategory.categoryId}
														subCategoryId={productSubCategory._id} title={productSubCategory?.title} imageURL={productSubCategory?.image[0] || ""} />
												)
											})
											:
											<img style={{ width: "248px" }} src="/assets/images/no-product-found.png" alt="" srcset="" />
									}
								</div>
								{/* <CustomPagination pagination={5} /> */}
							</section>
						</div>
					</section>





					<section className={style.products_to_change_section_wrapper}>


						<p>{t('productMileChangeSubHeading')}</p>
						<h1>{t('productByServiceHeading')}</h1>


						<ul className={style.products_by_services_tab}>
							{
								productSubCategoriesByServiceId.map((allproductSubCategoriesByServiceId) => {
									return (

										<li className={allproductSubCategoriesByServiceId._id === selectedServiceId ? `active` : ``} onClick={() => { productSubCatIdData(allproductSubCategoriesByServiceId._id) }}>{allproductSubCategoriesByServiceId.title}</li>
									)
								})
							}
							<li>{t('HomeProductChange1')}</li>
							<li>{t('HomeProductChange2')}</li>
							<li>{t('HomeProductChange3')}</li>
							<li>{t('HomeProductChange4')}</li>
							<li>{t('HomeProductChange5')}</li>
							<li>{t('HomeProductChange6')}</li>
							<li>{t('HomeProductChange7')}</li>
							<li>{t('HomeProductChange8')}</li>
						</ul>

						<div className={!productByServiceId ? `${style.no_product_found_text_wrapper} ProductCardsectt` : `ProductCardsectt`} >
							{
								productByServiceId && productByServiceId.length ?
									productByServiceId.map((allproductByServiceId) => {
										{ 
											
										}
										return (
											<ProductCard
											key={allproductByServiceId._id}
											productId={allproductByServiceId._id}
											subcategoryId={allproductByServiceId.subcategoryId}

											productThumbnailShort={allproductByServiceId?.thumbnail ? allproductByServiceId?.thumbnail[0] : ""}

											productTitle={capitalizeWords(allproductByServiceId.title)}
											productCurrency={allproductByServiceId.currency}
											productPrice={allproductByServiceId.price}
											promotionPrice={(allproductByServiceId.price*promotionDiscount)/100}
											productSku={allproductByServiceId.sku}
											vinNumber={allproductByServiceId.vin_number}
											product={allproductByServiceId}


											setcartCount={props.setcartCount}
											cartCount={props.cartCount}
											cartWishCount={props.cartWishCount}
											setCartWishCount={props.setCartWishCount}
										/>

										)
									})
									: <img
										style={{ height: '350px', width: '350px', objectFit: 'cover', marginInlineStart: '150%' }}
										src={NodDataImg} alt="" srcset="" />
							}




						</div>
						{/* <CustomPagination pagination={5} text="See All Products" /> */}

					</section>

					{/* <Pagination paginationPage={paginationPage} productPage={productByServiceId2} viewAllBtnLink='/product' /> */}

					{/* Section 2 */}
					<section className={style.products_by_services_section_wrapper}>

						<p>{t('productByServiceSubHeading')}</p>
						<h1>Products by services</h1>


						<ul className={style.products_by_services_tab}>
							{
								productSubCategoriesByServiceId2.map((allproductSubCategoriesByServiceId2) => {
									return (

										<li className={allproductSubCategoriesByServiceId2._id === selectedServiceId2 ? `active` : ``} onClick={() => { productSubCatIdData2(allproductSubCategoriesByServiceId2._id) }}  >{allproductSubCategoriesByServiceId2.title}</li>
									)
								})
							}
							<li>{t('HomeProductChange1')}</li>
							<li>{t('HomeProductChange2')}</li>
							<li>{t('HomeProductChange3')}</li>
							<li>{t('HomeProductChange4')}</li>
							<li>{t('HomeProductChange5')}</li>
							<li>{t('HomeProductChange6')}</li>
							<li>{t('HomeProductChange7')}</li>
							<li>{t('HomeProductChange8')}</li>
						</ul>
						<div className={!productByServiceId2 ? `${style.no_product_found_text_wrapper} ProductCardsectt` : `ProductCardsectt`}>
							{
								productByServiceId2 && productByServiceId2.length ?

									productByServiceId2.map((allproductByServiceId2) => {
									
										return (

											<ProductCard
											key={allproductByServiceId2._id}
											productId={allproductByServiceId2._id}
											subcategoryId={allproductByServiceId2.subcategoryId}
											productThumbnailShort={allproductByServiceId2?.thumbnail ? allproductByServiceId2?.thumbnail[0] : ""}

											productTitle={capitalizeWords(allproductByServiceId2.title)}
											productCurrency={allproductByServiceId2.currency}
											productPrice={allproductByServiceId2.price}
											promotionPrice={(allproductByServiceId2.price*promotionDiscount)/100}
											productSku={allproductByServiceId2.sku}
											vinNumber={allproductByServiceId2.vin_number}
											product={allproductByServiceId2}


											setcartCount={props.setcartCount}
											cartCount={props.cartCount}
											cartWishCount={props.cartWishCount}
											setCartWishCount={props.setCartWishCount}
										/>
										)
									})
									:
									<img
										style={{ height: '350px', width: '350px', objectFit: 'cover' }}
										src={NodDataImg} alt="" srcset="" />
							}
							{/* <ProductCard />

					<ProductCard />
					<ProductCard />

					<ProductCard /> */}

						</div>
						{/* <CustomPagination pagination={5} text="See All Products" /> */}

						{/* <Pagination paginationPage={paginationPage} productPage={productByServiceId2} viewAllBtnLink='/product' /> */}
					</section>

					<section className={style.car_campany_section_wrapper}>
						<p>{t('popularCarSubHeading')}</p>
						<h1>{t('popularCarHeading')}</h1>
						<div className={style.car_logo_section}>
							{
								brandsLists ?
									brandsLists.map((brand, i) => {

										return (
											<div>

												<img style={{ width: '100px' }} src={`${IMG_URL}/${brand?.image}`} alt={brand?.title} />
											</div>

										)
									})
									: <h3>Nothing to show</h3>
							}
						</div>
					</section>
					<section className={style.latest_news_section_wrapper}>
						<p className={style.latest_news_sub_heading}>{t('latestNewSubHeading')}</p>
						<h1 className={style.latest_news_heading}>{t('latestNewHeading')}</h1>
						<div className={style.latest_news_section}>
							<LatestNewsCard imageSrc={LatestNewsImage1} />
							<LatestNewsCard imageSrc={LatestNewsImage2} />
							<LatestNewsCard imageSrc={LatestNewsImage3} />
							<LatestNewsCard imageSrc={LatestNewsImage4} />

						</div>
						{/* <CustomPagination pagination={5} text="See All Products" /> */}

						{/* <Pagination viewAllBtnLink='/news-guide' viewAllBtnText='view all news' /> */}
					</section>
				</div>
			)}
		</>
	)
}
