import React from 'react'
import HeroImage from '../../components/hero-image/HeroImage';
import Image from '../../assets/images/contactUs.png';
import Input from '../../components/input/Input';
import style from './contactUs.module.css';
import { useTranslation } from "react-i18next";
import MapImage from '../../assets/images/map.png'
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../config/config';

import { toast } from 'react-toastify';

export default function ContactUs() {
   
    const fetchConfig = getFetchConfig();

    const { t } = useTranslation();

    const [adminDetails, setAdminDetails] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        message: ''
    });

    const [isSubmitted, setIsSubmitted] = useState(false);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const fetchAdmin_Details = () => {
        fetch(`${API_BASE_URL}/configuration/getAdminDetails`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((adminData) => {
               
                setAdminDetails(adminData.data[0]);

            })
            .catch(standardFetchHandlers.error);
    }


    const fetchCategory = () => {
        fetch(`${API_BASE_URL}/contactUs`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((e) => {
               

            })
            .catch(standardFetchHandlers.error);
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        fetch(`${API_BASE_URL}/contactUs/create`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify({
                userName: formData.name,
                contact_Number: formData.number,
                User_Email: formData.email,
                message: formData.message,
                reasonOfInquiry: formData.reasonOfInquiry
            }),
        })
            .then(handleFetchErrors)
            .then((data) => {
              
                toast.success(data?.message)
                setIsSubmitted(true);
                setFormData({
                    name: '',
                    number: '',
                    email: '',
                    message: '',
                    reasonOfInquiry: ''
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };




    // useEffect(() => {
    //     fetchCategory();
    //     fetchAdmin_Details();
    // }, []);

    return (
        <main className={style.main_wrapper}>
            <HeroImage HeroImage={Image} Heading={t('ContactUsHeading')} />
            <div className={style.contact_section_wrapper}>
                <section className={style.contact_detail_section}>
                    <div className={style.contact_detail_card}>
                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 22.3667V28.26C30.0002 28.682 29.8403 29.0883 29.5527 29.397C29.265 29.7057 28.8709 29.8938 28.45 29.9233C27.7217 29.9733 27.1267 30 26.6667 30C11.9383 30 0 18.0617 0 3.33333C0 2.87333 0.025 2.27833 0.0766667 1.55C0.106204 1.12907 0.294302 0.735018 0.603006 0.447348C0.91171 0.159678 1.31804 -0.000190722 1.74 4.27632e-07H7.63333C7.84006 -0.000208891 8.03949 0.0764317 8.19288 0.215034C8.34627 0.353636 8.44266 0.544305 8.46333 0.750001C8.50167 1.13333 8.53667 1.43833 8.57 1.67C8.90122 3.98154 9.58 6.22972 10.5833 8.33833C10.7417 8.67167 10.6383 9.07 10.3383 9.28333L6.74167 11.8533C8.94075 16.9774 13.0242 21.0609 18.1483 23.26L20.715 19.67C20.8199 19.5233 20.973 19.4181 21.1475 19.3728C21.322 19.3274 21.5069 19.3447 21.67 19.4217C23.7784 20.4231 26.026 21.1002 28.3367 21.43C28.5683 21.4633 28.8733 21.5 29.2533 21.5367C29.4587 21.5577 29.649 21.6543 29.7872 21.8076C29.9255 21.961 30.0019 22.1602 30.0017 22.3667H30Z" fill="#212F63" />
                        </svg>
                        <p>{t('ContactCall')}</p>
                        <span>{t('ContactCallNo')}</span>
                    </div>
                    <div className={style.contact_detail_card}>
                        <svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.6067 25.94L15 36.5467L4.39334 25.94C2.29557 23.8422 0.866978 21.1695 0.288214 18.2598C-0.290549 15.3501 0.00651141 12.3341 1.14183 9.5932C2.27715 6.85232 4.19974 4.50965 6.66647 2.86144C9.13321 1.21323 12.0333 0.333496 15 0.333496C17.9667 0.333496 20.8668 1.21323 23.3335 2.86144C25.8003 4.50965 27.7229 6.85232 28.8582 9.5932C29.9935 12.3341 30.2906 15.3501 29.7118 18.2598C29.133 21.1695 27.7044 23.8422 25.6067 25.94ZM15 22C16.7681 22 18.4638 21.2977 19.7141 20.0474C20.9643 18.7972 21.6667 17.1015 21.6667 15.3334C21.6667 13.5653 20.9643 11.8696 19.7141 10.6193C18.4638 9.36908 16.7681 8.6667 15 8.6667C13.2319 8.6667 11.5362 9.36908 10.286 10.6193C9.03572 11.8696 8.33334 13.5653 8.33334 15.3334C8.33334 17.1015 9.03572 18.7972 10.286 20.0474C11.5362 21.2977 13.2319 22 15 22ZM15 18.6667C14.116 18.6667 13.2681 18.3155 12.643 17.6904C12.0179 17.0653 11.6667 16.2174 11.6667 15.3334C11.6667 14.4493 12.0179 13.6015 12.643 12.9763C13.2681 12.3512 14.116 12 15 12C15.8841 12 16.7319 12.3512 17.357 12.9763C17.9822 13.6015 18.3333 14.4493 18.3333 15.3334C18.3333 16.2174 17.9822 17.0653 17.357 17.6904C16.7319 18.3155 15.8841 18.6667 15 18.6667Z" fill="#212F63" />
                        </svg>
                        <p>{t('ContactWeareHere')}</p>
                        <span>{t('ContactWeareHereText')}</span>
                    </div>
                    <div className={style.contact_detail_card}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_446_2165)">
                                <path d="M4.99967 5H34.9997C35.4417 5 35.8656 5.17559 36.1782 5.48816C36.4907 5.80072 36.6663 6.22464 36.6663 6.66667V33.3333C36.6663 33.7754 36.4907 34.1993 36.1782 34.5118C35.8656 34.8244 35.4417 35 34.9997 35H4.99967C4.55765 35 4.13372 34.8244 3.82116 34.5118C3.5086 34.1993 3.33301 33.7754 3.33301 33.3333V6.66667C3.33301 6.22464 3.5086 5.80072 3.82116 5.48816C4.13372 5.17559 4.55765 5 4.99967 5ZM20.0997 19.4717L9.41301 10.3967L7.25467 12.9367L20.1213 23.8617L32.7563 12.9283L30.5763 10.4067L20.1013 19.4717H20.0997Z" fill="#212F63" />
                            </g>
                            <defs>
                                <clipPath id="clip0_446_2165">
                                    <rect width="40" height="40" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <p>{t('ContactWriteUs')}</p>
                        <span>{t('ContactWriteUsEmail')}</span>
                    </div>
                </section>
                <section className={style.contact_form_section_wrapper}>
                    <p className={style.sub_heading}>{t('ContactGetContact')}</p>
                    <h1>{t('ContactSendUsMsg')}</h1>
                    <p className={style.contact_us_desc}>{t('ContactText')}.</p>

                    <form id={style.contact_us_from} onSubmit={handleSubmit}>
                        <input
                            type='text'
                            name='name'
                            placeholder={t('ContactPlaceholderName')}
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type='number'
                            name='number'
                            placeholder={t('ContactPlaceholderNumber')}
                            value={formData.number}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type='email'
                            name='email'
                            placeholder={t('ContactPlaceholderEmail')}
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <div style={{ position: 'relative', width: '100%', marginBottom: '1rem' }}>
                            <select
                                name='reasonOfInquiry'
                                value={formData.reasonOfInquiry}
                                onChange={handleInputChange}
                                placeholder='Buyer Category'
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    background: 'white',
                                    border: '1px solid #ccc',
                                    borderRadius: '2px',
                                                               }}
                            >
                                <option value='' disabled selected hidden>Buyer Category</option>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </select>
                            <span style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                pointerEvents: 'none',
                                color: '#333333'
                            }}>
                                ▼
                            </span>
                        </div>
                        <textarea
                            cols="100"
                            id='span-2'
                            name='message'
                            placeholder={t('ContactPlaceholdermessage')}
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                            rows={3}
                        ></textarea>

                        <button className='btn1' id="span-2" type="submit">{t('ContactPlaceholderbtn')}</button>
                    </form>

                    {isSubmitted && (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            border: '1px solid #53B351',
                            padding: '10px',
                            color: '#53B351',
                            textAlign: 'center',
                            marginTop: '10px',
                            marginBottom: '20px'
                        }}>
                            <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.99974 9.67152L16.1917 0.478516L17.6067 1.89252L6.99974 12.4995L0.635742 6.13552L2.04974 4.72152L6.99974 9.67152Z" fill="#53B351" />
                            </svg>
                            <span style={{ marginLeft: '10px', fontSize: '14px' }}>{t('ContactThankYouMessage')}</span>
                        </div>
                    )}

                </section>
                <section className={style.map_section}>
                    <img style={{ with: '100%' }} src={MapImage} alt="" srcset="" />
                </section>
            </div>
        </main>
    )
}
