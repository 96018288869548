
import style from "./registerCard.module.css";

import Input from '../../input/Input';
import Buttons from '../../button/Button';
import SocialAuthBtn from '../social-auth-btn/SocialAuthBtn';
import { useTranslation } from "react-i18next";
import getMultiConfig from '../../../utils/getMultipartConfig';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import getFetchConfig from '../../../utils/getFetchConfig';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import { API_BASE_URL } from "../../../config/config";

import { isTruthyString, isValidEmailAddress, isValidIndianMobileNumber } from '../../../utils/verification';

export default function RegisterCard({ toggleModalForLogin = () => { }, updateModalVisibility = null }) {
    const { t } = useTranslation();
    const imageURL = `https://admin-anycarpart.go-demo.com`;
    const Cart = window.localStorage.getItem('ProductCart')
    const CartProduct = Cart ? JSON.parse(Cart) : [];
    const wishList = window.localStorage.getItem('Product')
    const wishListProduct = wishList ? JSON.parse(wishList) : []
    let token = window.localStorage.getItem('token')
    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureUrl, setProfilePictureUrl] = useState(null);

    const navigate = useNavigate();
    const fetchConfig = getFetchConfig();

    const [showLoader, toggleLoader] = useState(false),
        [formData, setFormData] = useState({
            first_name: ``,
            last_name: ``,
            email_address: ``,
            password: ``,
            confirm_password: ``,
            phone_number: ``,
            userRole: 'Customer',
            companyName: '',
            newsLetter: 'Unsubscribe',

        });

    const [isMobileNumberExist, updateIsMobileNumberExist] = useState(false);
    const multiConfig = getMultiConfig();

    const {
        first_name,
        last_name,
        email_address,
        password,
        confirm_password,
        phone_number,
        userRole,
        companyName,
        newsLetter,


    } = formData;

    const onChangeHandlerForFormData = (e) => {
        const inputName = e?.target?.name || e?.name,
            inputValue = e?.target?.value || e?.value;
        const value = inputName === 'newsLetter' ? e.target.checked : inputValue;

        setFormData((prev) => ({
            ...prev,
            [inputName]: inputValue
        }));
    };
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        toast.dismiss();
        const missingData = [],
            invalidData = [];

        // Validation checks for form fields
        if (!isTruthyString(last_name)) missingData.push(`Last Name`);
        if (!isTruthyString(first_name)) missingData.push(`First Name`);
        if (!email_address) missingData.push(`email address`);
        else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`email address`);
        if (!password) missingData.push(`password`);
        else if (password && !isTruthyString(password)) invalidData.push(`password`);
        if (!confirm_password) missingData.push(`password confirmation`);
        else if (confirm_password && !isTruthyString(confirm_password)) invalidData.push(`password confirmation`);
        if (!phone_number) missingData.push(`Phone number`);

        // Show errors if needed
        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);
            return;
        }

        // Constructing form data object
        const formData = new FormData();
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('email_address', email_address);
        formData.append('password', password);
        formData.append('confirm_password', confirm_password);
        formData.append('phone_number', phone_number);
        formData.append('device_token', 'token');
        formData.append('device_type', 1);
        formData.append('userRole', userRole || 'Customer');
        formData.append('companyName', companyName);
        formData.append('newsLetter', newsLetter === 'Subscribe' ? 'Subscribe' : 'Unsubscribe');


        if (profilePicture) {
            formData.append('profile_picture', profilePicture);
        }

    

        // Fetching data
        fetch(`${API_BASE_URL}/account/signin`, {
            ...multiConfig,
            method: 'POST',
            body: formData,
        })
            .then(handleFetchErrors)
            .then((res) => {
                toggleLoader(false);
                if (res?.data?.status == 201) {
                    toast.success(res?.data?.message);
                    updateModalVisibility(false);
                } else if (res?.status == 403 || res?.status == 400) {
                    toast.error(res?.data?.message);
                }
            })
            .catch((err) => {
                console.log(err)
            });
    };



    const toggleSubscription = () => {
        const value = newsLetter === 'Subscribe' ? 'Unsubscribe' : 'Subscribe';
        setFormData((prev) => ({
            ...prev,
            newsLetter: value
        }));
    };

    const handleRoleChange = (event) => {
        setFormData((prev) => ({
            ...prev,
            userRole: event.target.value
        }));
    };
    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        setProfilePicture(file);
        setProfilePictureUrl(URL.createObjectURL(file)); // Set URL for preview
    };

    return (
        <div className={style.card_wrapper}>

            <span className={style.heading}>{t('RegistrLoginReg')}</span>
            <h2 className={style.heading_description}>{t('RegistrLoginCreate')}</h2>

            <div className={style.form_and_social_login_btn_section}>
                <form onSubmit={onSubmitHandler} className={style.form}>
                <div style={{ marginBottom: '15px' }}>
                        <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Profile Picture</label>
                        <input
                            type='file'
                            name='profile_picture'
                            accept='image/*'
                            onChange={handleProfilePictureChange}
                            style={{ display: 'none' }} // Hide the default file input
                        />
                        <button
                            type='button'
                            style={{
                                backgroundColor: '#212F63',
                                color: '#fff',
                                border: 'none',
                                padding: '8px 12px',
                                borderRadius: '2px',
                                cursor: 'pointer',
                                fontSize: '10px'

                            }}
                            onClick={() => document.querySelector('input[name="profile_picture"]').click()}
                        >
                            Upload Picture
                        </button>
                    </div>

                    {profilePictureUrl && (
                        <div style={{ borderRadius: '50%', overflow: 'hidden', width: '100px', height: '100px' }}>
                            <img src={profilePictureUrl} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    )}
                    <div>
                        <label style={{ fontSize: '14px' }}>Role: *</label>
                        <div style={{ display: 'flex' }}>
                            <label style={{ marginBottom: '5px', fontSize: '14px' }}>
                                <input
                                    type="radio"
                                    name="userRole"
                                    value="Customer"
                                    checked={userRole === 'Customer'}
                                    onChange={handleRoleChange}
                                    style={{ marginRight: '10px', marginLeft: '30px', transform: 'scale(1.5)' }}
                                />
                                Customer
                            </label>
                            <label style={{ marginBottom: '5px', fontSize: '14px' }}>
                                <input
                                    type="radio"
                                    name="userRole"
                                    value="Company"
                                    checked={userRole === 'Company'}
                                    onChange={handleRoleChange}
                                    style={{ marginRight: '10px', marginLeft: '10px', transform: 'scale(1.5)' }}
                                />
                                Company
                            </label>
                        </div>
                    </div>

                


                    {userRole === 'Company' && (
                        <Input
                            type='text'
                            placeholder='Company Name'
                            label='Company Name'
                            name='companyName'
                            value={companyName}
                            onChangeHandler={onChangeHandlerForFormData}
                            required={true}
                        />
                    )}

                    <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label style={{ marginRight: '10px', fontSize: '14px' }}>NewsLetter:</label>
                        <div
                            style={{
                                width: '20px',
                                height: '20px',
                                border: '1px solid #000',
                                borderRadius: '3px',
                                marginRight: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                            onClick={toggleSubscription}
                        >
                            {newsLetter === 'Subscribe' && (
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        backgroundColor: '#0e1731',
                                        borderRadius: '3px'
                                    }}
                                ></div>
                            )}
                        </div>
                        <span style={{ fontSize: '14px' }}>Subscribe</span>
                    </div>

                    <Input
                        type='text'
                        placeholder={t('RegistrLoginPhone')}
                        label={t('RegistrLoginPhone')}
                        required={true}
                        name='phone_number'
                        value={phone_number}
                        onChangeHandler={onChangeHandlerForFormData}
                    />

                    <Input
                        type='email'
                        placeholder={t('RegistrLoginEmail')}
                        label={t('RegistrLoginEmail')}
                        name='email_address'
                        value={email_address}
                        onChangeHandler={onChangeHandlerForFormData}
                        required={true}
                    />

                    <Input
                        type='text'
                        placeholder={t('RegistrLoginFirst')}
                        label={t('RegistrLoginFirst')}
                        name='first_name'
                        value={first_name}
                        onChangeHandler={onChangeHandlerForFormData}
                        required={true}
                    />

                    <Input
                        type='text'
                        placeholder={t('RegistrLoginLast')}
                        label={t('RegistrLoginLast')}
                        name='last_name'
                        value={last_name}
                        onChangeHandler={onChangeHandlerForFormData}
                        required={true}
                    />

                    <Input
                        type='password'
                        placeholder={t('RegistrLoginPass')}
                        label={t('RegistrLoginPass')}
                        name='password'
                        value={password}
                        onChangeHandler={onChangeHandlerForFormData}
                        required={true}
                    />

                    <Input
                        type='password'
                        placeholder={t('RegistrLoginPassConfirm')}
                        label={t('RegistrLoginPassConfirm')}
                        name='confirm_password'
                        value={confirm_password}
                        onChangeHandler={onChangeHandlerForFormData}
                        required={true}
                    />

                    <div className={style.create_login_btn}>
                        <input type="submit" className='btn1' value={t('RegistrLoginCreateAcc')} />

                        <p className={style.register_btn1} onClick={() => { toggleModalForLogin(true) }} >{t('RegistrLoginSignIn')}</p>
                    </div>

                    <p className={style.agree_text}>{t('RegistrLoginAgree')}<b>{t('RegistrLoginAgreeUser')}</b></p>


                </form>
                <SocialAuthBtn heading={t('RegistrLoginThrough')} updateModalVisibility={updateModalVisibility} />

            </div>
        </div>
    )
}
