import React from 'react'
import MemberLayout from '../../layouts/memberLayout/memberLayout'
import style from './myaccount.module.css';
import { useTranslation } from "react-i18next";

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import getFetchConfig from '../../utils/getFetchConfig';
import getMultiConfig from '../../utils/getMultipartConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL, IMG_URL } from '../../config/config';
import DynamicFieldComponents from '../../components/order/DynamicFieldComponents';
import { event } from 'jquery';
import { Padding } from '@mui/icons-material';
import CancelPopup from '../../components/order/successfulPopup';
import ReturnOrder from '../../components/order/returnOrder';
import RefundModal from '../../components/OrderRequests/refundableAmountModal';



export default function ReturnOrderPage() {


    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const imageURL = `https://admin-anycarpart.go-demo.com`;
    const fetchConfig = getFetchConfig();
    const { t } = useTranslation();
    const multiConfig = getMultiConfig();
    const [productOrder, setProductOrder] = useState([]);
    const [address, setAddress] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const location = useLocation();
    const message = new URLSearchParams(location.search).get('message');
    const [isDamaged, setIsDamaged] = useState(false);
    const [damagedImages, setDamagedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const [isCancelPopupVisible, setCancelPopupVisible] = useState(false);

    const [refundAmount, setRefundAmount] = useState(0);
    // State for form inputs
    const [accountDetails, setAccountDetails] = useState('');

    const handleOptionChange = (event) => {
        setIsDamaged(event.target.value === 'true');
    };

    const handleOptionChangeNo = (event) => {
        setIsDamaged(event.target.value === 'true');
    };

    const handleImageChange = (event) => {
        setDamagedImages([...damagedImages, ...Array.from(event.target.files)]);
    };

    const handleRemoveImage = (indexToRemove) => {
        setDamagedImages(damagedImages.filter((_, index) => index !== indexToRemove));
    };
    const [selectedItems, setSelectedItems] = useState([]);
    const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false);
    const { orderId } = useParams();



    const getProductOrder = (orderId) => {
        fetch(`${API_BASE_URL}/productOrder/single-order/${orderId}`, {
            ...fetchConfig,
            method: 'GET',
        })
            .then(handleFetchErrors)
            .then((dataplace) => {
                
                // Make sure to check if the data is available before accessing its properties
                if (dataplace.data && dataplace.data.order) {
                    const { order } = dataplace.data;
                    
                    setProductOrder(order);
                    setAddress(order.shippingAddress);
                    setOrderDetails(order.orderDetail);
                }
            })
            .catch(standardFetchHandlers.error);
    };



    const [fields, setFields] = useState([]);
    const [dynamicFields, setDynamicFields] = useState([]);



    const handleDynamicFields = (ques, response) => {
        dynamicFields[`${ques}`] = response

        
    }
    const getRequestForm = async (type) => {
        try {
            const response = await fetch(`${IMG_URL}admin/order/get-request-form?type=${type}`, {
                ...fetchConfig,
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch request form');
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Error fetching request form:', error);
            throw error;
        }
    };




    useEffect(() => {
        const fetchRequestForm = async () => {
            try {
                const formData = await getRequestForm('return');
                setFields(formData.fields);
                const convertedObject = {};
                formData.fields.map(item => { return { [item.label]: "" } }).forEach(item => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    convertedObject[key] = value;
                });
                setDynamicFields(convertedObject)
            } catch (error) {
                console.error('Error fetching request form:', error);
            }
        };

        fetchRequestForm();
    }, []);


    useEffect(() => {
        getProductOrder(orderId);
    }, [orderId]);

    const navigatorHandler = (id) => {
        navigate(`/paymentForm/${id}`);
    };

    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (orderIndex) => {
        const isAlreadyRequested = selectedItems.includes(orderIndex);
        const isAlreadyCancelled = productOrder.orderDetail[orderIndex].isCancelled;
        const isAlreadySelected = selectedItems.includes(orderIndex);
        if (!isAlreadyCancelled && !isAlreadyRequested) {
            setSelectedItems((prevSelectedItems) => [...prevSelectedItems, orderIndex]);
        } else if (isAlreadySelected) {
            setSelectedItems(prevSelectedItems => prevSelectedItems.filter(index => index !== orderIndex));
        }
    };

    const handleSelectAllChange = () => {
        setSelectAll((prevSelectAll) => !prevSelectAll);
        setSelectedItems((prevSelectedItems) =>
            selectAll ? [] : productOrder.orderDetail.map((_, index) => index)
        );
    };

    const [reason, setReason] = useState('');
    const [remarks, setRemarks] = useState('');

    const handleSubmitRequest = () => {
        if (selectedItems.length === 0) {
            toast.error("Please select at least one product to cancel.");
            return;
        }

        const accountDetailsValue = document.getElementById('accountDetails').value;
        if (!accountDetailsValue.trim()) {
            toast.error("Please provide account details.");
            return;
        }

        const formData = new FormData();
        formData.append("orderId", orderId);
        selectedItems.forEach(index => {
            formData.append("orderItems", productOrder.orderDetail[index]._id);
        });
        formData.append("type", "return");
        formData.append("accountDetails", accountDetailsValue);
        formData.append("isDamaged", isDamaged);
        
        // Serializing JSON data for `fields`
        formData.append("fields", JSON.stringify(dynamicFields));

        if (isDamaged) {
            damagedImages.forEach((image, index) => {
                formData.append(`damagedImages`, image, image.name);
            });
        }
        setIsLoading(true); // Show loader

        fetch(`${API_BASE_URL}/productOrder/order-return-request`, {
            ...multiConfig,
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                
                toast.success("Return request submitted successfully.");
                setCancelPopupVisible(true); // Set the state to true to show the modal
            })
            .catch(error => {
                console.error('Error submitting request:', error);
                toast.error("Error submitting Return request. Please try again later.");
            })
            .finally(() => {
                setIsLoading(false); 
              });
    };

    const handleCalculateRefund = (e) => {
        e.preventDefault();
        const formData = {
            "orderItems": selectedItems.map(item => productOrder.orderDetail[item]._id),
            "type": "return", //"cancel", "return", "refund"
            "isDamaged": isDamaged
        }

        fetch(`${API_BASE_URL}/productOrder/calculate-refundable-amount`, {
            // ...multiConfig,
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify(formData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.refundableAmount !== undefined) {
                    setRefundAmount(data.refundableAmount);
                    setIsRefundModalOpen(true);  // Show the modal with the refund amount
                } else {
                    throw new Error('Refundable amount is not available');
                }
            })
            .catch(error => {
                console.error('Failed to fetch refundable amount:', error);
                toast.error("Failed to calculate refundable amount.");
            });
    };

    const performFinalSubmit = () => {
        handleSubmitRequest();
        setIsRefundModalOpen(false);
    };
    const Loader = () => (
        <div style={{
            border: '5px solid #f3f3f3', /* Light grey */
            borderTop: '5px solid #212F63', /* Blue */
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            animation: 'spin 1s linear infinite'
        }}>
            <style>
                {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
            </style>
        </div>
    );
    return (
        <MemberLayout>
            <div >
                <div  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <a href="/orders" style={{ color: "black", textDecoration: "none" }}>
                            My Order
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.98384 2.95845C7.22792 2.71437 7.62365 2.71437 7.86772 2.95845L13.3011 8.39178C14.1868 9.27753 14.1868 10.7233 13.3011 11.609L7.86772 17.0423C7.62365 17.2864 7.22792 17.2864 6.98384 17.0423C6.73976 16.7983 6.73976 16.4025 6.98384 16.1584L12.4172 10.7251C12.8148 10.3275 12.8148 9.67326 12.4172 9.27567L6.98384 3.84233C6.73976 3.59825 6.73976 3.20253 6.98384 2.95845Z" fill="#201A18" />
                        </svg>
                        <a href="#" style={{ color: "black", textDecoration: "none" }}>
                            Request for return
                        </a>
                    </div>
                    <div >
                        <h1 style={{ fontSize: 32, fontWeight: '700' }}>Request for return</h1>
                        <div className={style.account_order_accordion_list_bar}>
                            {productOrder ? (
                                <>
                                    <div style={{ backgroundColor: '#F7F7F7' }} >
                                        <div className={style.order_lists_bar}>
                                            <div className={style.accordion_lists_bar}>
                                                <div>
                                                    <p>Order No</p>
                                                    <span>{productOrder._id}</span>
                                                </div>

                                            </div>
                                            <div className={style.accordion_lists_bar2}>

                                                <div>

                                                    <p>Status</p>
                                                    <span className={style.orange_text} >{productOrder.status}</span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className={style.account_order_accordion_list_bar} style={{ padding: "0px 20px 0px 20px" }}>
                                            {/* {productOrder.status == "payment failed" ?
                                                <button onClick={() => { navigatorHandler(productOrder._id) }} className={style.place_order_btn}>
                                                    Retry Payment
                                                </button>
                                                : null} */}

                                            <div style={{ width: '100%' }}>
                                                <table class="table">
                                                    <thead  >
                                                        <tr style={{ borderBottom: "20px solid #F7F7F7" }}>
                                                            <th style={{ backgroundColor: "#6F758B", paddingLeft: "20px", textTransform: 'capitalize' }} scope="col">
                                                                <input
                                                                    style={{ backgroundColor: '#6F758B', display: "block" }}
                                                                    type="checkbox"
                                                                    disabled={productOrder?.orderDetail?.some(order => order.status === "return request")}

                                                                    onChange={handleSelectAllChange}
                                                                    checked={selectAll}

                                                                />
                                                            </th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Product</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Description</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Price</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Quantity</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Total</th>
                                                        </tr>
                                                    </thead>

                                                    {productOrder?.orderDetail?.length > 0 && productOrder?.orderDetail?.map((order, orderIndex) => (
                                                        // <div
                                                        //     disabled={order.status == "cancel request"}
                                                        //     className={`${style.checkout_items_products}  ${order.status == "cancel request" ? style.disabled : ''}`}
                                                        //     // className={style.disabled}
                                                        //     key={orderIndex}
                                                        // >
                                                        //     <div style={{ Padding: 10 }} key={orderIndex} className={style.checkout_items_oil_filters}>
                                                        <tbody key={orderIndex}>
                                                            {/* <tr style={{ backgroundColor: "white", marginBottom: "20px", borderBottom: "20px solid #F7F7F7" }}>
                                                             */}
                                                            <tr style={{ backgroundColor: order.status === "return request" ? "#F7F7F7" : "white", marginBottom: "20px", borderBottom: "20px solid #F7F7F7" }} key={orderIndex}>
                                                                
                                                                <th scope="row" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <input
                                                                        style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#6F758B', display: "block" }}
                                                                        type="checkbox"
                                                                        disabled={(order.status == "return request") || (order.status == "cancel request")}
                                                                        onChange={() => handleCheckboxChange(orderIndex)}
                                                                        checked={selectAll || selectedItems.includes(orderIndex)}
                                                                    />
                                                                </th>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <img src={order.productImage ? `${IMG_URL}/${order.productImage}` : null}
                                                                            alt="" style={{ width: "20px", height: "20px", marginRight: "10px" }} />

                                                                        {/* <img src={`${imageURL}/${order.productImage}`} alt="" /> */}
                                                                        <h5 style={{ fontSize: 14, fontWeight: '500' }}>{order.productName}</h5>
                                                                    </div>
                                                                </td>



                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h4 style={{ fontSize: 14, fontWeight: '500', color: '#6F758B' }}>{order.productDescription && order.productDescription.slice(0, 20)}</h4>
                                                                </td>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h5 style={{ fontSize: 14, fontWeight: '500' }}>{order.price}SAR</h5>
                                                                </td>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h4 style={{ fontSize: 14, fontWeight: '500' }}>{order.quantity} </h4>
                                                                </td>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h5 style={{ fontSize: 14, fontWeight: '500', color: '#FD7E14' }}>{order.price}SAR</h5>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        //     </div>
                                                        // </div>
                                                    ))}
                                                </table>

                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div style={{ marginBottom: 20 }}>

                        </div>
                        {/* <ReturnOrder
                            reason={reason}
                            remarks={remarks}
                            onReasonChange={handleReasonChange}
                            onRemarksChange={handleRemarksChange}
                            onSubmit={handleSubmitRequest}
                        /> */}
                        <div style={{ paddingBottom: 25 }}>
                            <div style={{ backgroundColor: '#F7F7F7', paddingInline: '20px', paddingBottom: '20px', paddingTop: '20px', borderRadius: '5px' }}>
                                <b style={{ color: "black" }}>Enter your account details:</b>

                                <textarea
                                    id="accountDetails"
                                    style={{
                                        width: '100%',
                                        paddingin: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                    rows={4}
                                    cols={50}
                                    placeholder="Enter your account details here..."
                                ></textarea>
                            </div>
                            <div style={{ backgroundColor: '#F7F7F7', paddingInline: '20px', paddingBottom: '20px', borderRadius: '5px' }}>

                                <b style={{ color: "black" }}>Is Product Damaged?</b>

                                <div className="form-check" style={{ display: 'flex', flexDirection: 'column' }}>


                                    <label style={{ marginBottom: '5px' }}>
                                        <input
                                            type="radio"
                                            name="isDamaged"
                                            value={true}
                                            onChange={handleOptionChange}
                                            style={{ marginRight: '5px', transform: 'scale(1.5)' }}
                                        />
                                        Yes
                                    </label>
                                    <label style={{ marginBottom: '5px' }}>
                                        <input
                                            type="radio"
                                            name="isDamaged"
                                            value={false}
                                            onChange={handleOptionChangeNo}
                                            style={{ marginRight: '5px', transform: 'scale(1.5)' }}
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                            {isDamaged && (
                                <div style={{ backgroundColor: '#F7F7F7', padding: '20px', borderRadius: '5px', marginTop: '20px' }}>
                                    <label style={{ color: 'black', fontWeight: 'bold', display: 'block', marginBottom: '10px' }}>
                                        Upload Images of Damaged Product:
                                        <input type="file" onChange={handleImageChange} multiple style={{ marginTop: '5px' }} />
                                    </label>
                                    <p style={{ fontSize: '0.9em', marginTop: '5px', color: 'gray' }}>You can upload a maximum of 5 images.</p>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {damagedImages.map((image, index) => (
                                            <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
                                                <img src={URL.createObjectURL(image)} alt={`Damaged Product ${index + 1}`} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
                                                <button onClick={() => handleRemoveImage(index)} style={{ position: 'absolute', top: 0, right: 0, padding: '2px 5px', color: 'white', backgroundColor: 'red', border: 'none', cursor: 'pointer' }}>
                                                    ×
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        <DynamicFieldComponents
                            fields={fields}
                            handleDynamicFields={handleDynamicFields}
                        // onSubmit={handleCalculateRefund}
                        />

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            {isCancelPopupVisible ? (
                                <p style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#212F63',
                                    padding: '12px 30px',
                                    borderRadius: '5px',
                                    backgroundColor: '#F0F8FF', // Light background for visibility
                                    textAlign: 'center',
                                    border: '1px solid #212F63'
                                }}>
                                    Request Submitted
                                </p>
                            ) : (
                                isLoading ? (
                                    <Loader />
                                ) : (
                                    <button
                                        onClick={handleCalculateRefund}
                                        style={{
                                            backgroundColor: '#212F63',
                                            color: '#fff',
                                            padding: '12px 30px',
                                            borderRadius: '5px',
                                            border: 'none',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Submit
                                    </button>
                                )
                            )}
                        </div>

                        <Link to={'/orders'}>
                            {isCancelPopupVisible && <CancelPopup title="Your return order request submitted successfully!" onClose={() => setCancelPopupVisible(false)} />}
                        </Link>
                        <RefundModal
                            isOpen={isRefundModalOpen}
                            onClose={() => setIsRefundModalOpen(false)}
                            onConfirm={performFinalSubmit} // Perform final submission steps

                            refundAmount={refundAmount}
                        />


                        {/* <Link to={'/orders'}>
                            {isCancelPopupVisible && <CancelPopup onClose={() => setCancelPopupVisible(false)} />}
                        </Link> */}
                    </div>
                </div>

            </div>






        </MemberLayout>
    )
}
