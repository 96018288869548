import React, { useState, useEffect } from 'react';
import style from './Checkoutshipping.module.css';

import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import Loader from '../../components/loader/Loader';
import Country from '../../Country.json'
import { API_BASE_URL, IMG_URL } from '../../config/config';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const validationIndivaulaSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('First name is required')
        .matches(/^[A-Za-z\s]+$/, 'First name must be a string'),
    lastname: Yup.string().required('Last name is required').matches(/^[A-Za-z]+$/, 'Last name must be a string'),
    phonenumber: Yup.string()
        .required('Phone number is required')
        .matches(/^[0-9]{10,12}$/, 'Phone number must be a number with 10-12 digits'),
    //  companyname: Yup.string().required('Company name is required'),
    //   taxnumber: Yup.string().required('Tax number is required').matches(/^[a-zA-Z0-9]+$/, 'Tax number must be alphanumeric'),
});



const validationCompanySchema = Yup.object().shape({
    companyname: Yup.string().required('Company name is required'),
    taxnumber: Yup.string().required('Tax number is required').matches(/^[a-zA-Z0-9]+$/, 'Tax number must be alphanumeric'),
    phonenumber: Yup.string()
        .required('Phone number is required')
        .matches(/^[0-9]{10,12}$/, 'Phone number must be a number with 10-12 digits'),
});


// import { coupon } from '../../../../anycarpart-admin/controllers/api/coupon/coupon';

export default function Checkoutshipping() {
    
    // const API_BASE_URL = `https://admin-anycarpart.go-demo.com/api/v1`;
    const imageURL = `https://admin-anycarpart.go-demo.com`;
    const fetchConfig = getFetchConfig();
    let coupon = window.localStorage.getItem('coupon')
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true);
    const [allCartProduct, setAllCartProduct] = useState([]);
    const [userProfileInfo, setUserProfileInfo] = useState(``);
    const [selectedCity, setSelectedCity] = useState({ city: '', id: '' });
    const [userAllProfile, setUserAllProfile] = useState([]);
    const [cartOrderSummary, setCartOrderSummary] = useState({ subTotal: 0, discount: 0, shippingCost: 0, vat: 0, total: 0, afterDiscount: 0 });
    const [city, setCity] = useState([]);
    const [storeList, setStoreList] = useState([]);

    const [isDoorStepChecked, setIsDoorStepChecked] = useState(true);
    const [isStorePickUpChecked, setIsStorePickUpChecked] = useState(false);
    const [isBillingShippingSame, setIsBillingShippingSame] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [Telephone, setTelephone] = useState()
    const [selectedOptions, setSelectedOptions] = useState('Individual')
    const [userinfo, setuserinfo] = useState({ firstname: '', lastname: '', phonenumber: '', companyname: '', taxnumber: '' })
    const [Pickupstore, setPickupstore] = useState({ state: '', city: '', store: '' })
    const [errors, setErrors] = useState({});
    const [region, setregion] = useState([])
    const [cities, setcities] = useState([])
    const [promotionDiscount, setPromotionDiscount] = useState(null);
    const [shippingCities, setShippingCities] = useState([]);
    const [billingCities, setBillingCities] = useState([]);


   
    const [shippingAddress, setShippingAddress] = useState(
        {
            address: "",
            country: "Saudi Arabia",
            state: "",
            city: "",
            zipCode: "",
            buildingNumber: ""
        },
    );

    const [billingAddress, setBillingAddress] = useState(
        {
            address: "",
            country: "Saudi Arabia",
            state: "",
            city: "",
            zipCode: "",
            buildingNumber: ""
            
        },
    );
    const resetUserAddressInfo = () => {
        setShippingAddress({
            address: "",
            country: "Saudi Arabia",
            state: "",
            city: "",
            zipCode: "",
            buildingNumber: ""
        });
        setBillingAddress({
            address: "",
            country: "Saudi Arabia",
            state: "",
            city: "",
            zipCode: "",
            buildingNumber: ""
        })

      };


    const fetchAllCartProduct = () => {
        fetch(`${API_BASE_URL}/cart/getAllCartProduct`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                couponCode: coupon
            })
        })
            .then(handleFetchErrors)
            .then((dataAllCartProduct) => {
                setAllCartProduct(dataAllCartProduct.data.product);
                setCartOrderSummary({ subTotal: dataAllCartProduct.data.subTotal, discount: dataAllCartProduct.data.discount, shippingCost: dataAllCartProduct.data.shippingCost, vat: dataAllCartProduct.data.vat, total: dataAllCartProduct.data.total })

            })
            .catch(standardFetchHandlers.error);

    };
    const fetchUserAllProfile = () => {

        fetch(`${API_BASE_URL}/profile`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((dataUserAllProfile) => {
                
                let parts = dataUserAllProfile.data.user.userInfo.first_name.split(" ")
                const firstName = parts[0];
                //console.log(firstName, "nn")
                const lastName = dataUserAllProfile.data.user.userInfo.last_name ? dataUserAllProfile.data.user.userInfo.last_name : parts.slice(1).join(" ");
                // setUserAllProfile(dataUserAllProfile.data.user);
                // setUserProfileInfo(dataUserAllProfile.data.user.userInfo);
                setShippingAddress({
                    address: dataUserAllProfile.data.userAddressBilling.address,
                    country: dataUserAllProfile.data.userAddressBilling.country,
                    state: dataUserAllProfile.data.userAddressBilling.state,
                    city: dataUserAllProfile.data.userAddressBilling.city,
                    zipCode: dataUserAllProfile.data.userAddressBilling.zipCode,
                })
                setBillingAddress({
                    address: dataUserAllProfile.data.userAddressShipping.address,
                    country: dataUserAllProfile.data.userAddressShipping.country,
                    state: dataUserAllProfile.data.userAddressShipping.state,
                    city: dataUserAllProfile.data.userAddressShipping.city,
                    zipCode: dataUserAllProfile.data.userAddressShipping.zipCode,
                })
                setuserinfo({ firstname: firstName, lastname: lastName, phonenumber: dataUserAllProfile.data.user.userInfo.phone_number, companyname: '', taxnumber: '' })

            })
            .catch(standardFetchHandlers.error);
    };
    const fetchCity = () => {

        fetch(`${API_BASE_URL}/city`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((dataCity) => {
                console.log(dataCity.data, "ggg")
                setCity(dataCity.data.city);
            })
            .catch(standardFetchHandlers.error);
    };

    const fetchStoreList = () => {
        if(selectedCity.id){
            fetch(`${API_BASE_URL}/city/storeList`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    cityId: selectedCity.id,

                })
            })
                .then(handleFetchErrors)
                .then((dataStoreList) => {
                  
                    setStoreList(dataStoreList.data.store);

                })
                .catch(standardFetchHandlers.error);
        }
    };
    

    const handleSubmit = (e) => {
        if (selectedOptions === "Individual") {
            if (!userinfo.firstname || !userinfo.lastname || !userinfo.phonenumber) {
                toast.error('PERSONAL INFORMATION:All fields are required');
                return
            }
        } else if (selectedOptions === "company") {
            if (!userinfo.companyname || !userinfo.taxnumber || !userinfo.phonenumber) {
                toast.error('PERSONAL INFORMATION:All fields are required');
                return
            }
        }
        if (isDoorStepChecked) {
           // console.log(shippingAddress, "shippingAddress");
            if (!shippingAddress.address || !shippingAddress.country || !shippingAddress.state || !shippingAddress.city || !shippingAddress.zipCode || !shippingAddress.buildingNumber) {
                toast.error('Shipping Address: All fields are required');
                return
            }
            if (!isBillingShippingSame) {
               // console.log(isBillingShippingSame, "isBillingShippingSame");
                if (!billingAddress.address || !billingAddress.country || !billingAddress.state || !billingAddress.city || !billingAddress.zipCode) {
                    toast.error('Billing Address: All fields are required');
                    return
                }
            }
        }

        let addressType = isBillingShippingSame ? shippingAddress : billingAddress;
        fetch(`${API_BASE_URL}/userAddress/addressStore`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify({
                shippingAddress: {
                    address: shippingAddress.address,
                    country: "Saudi Arabia",
                    state: shippingAddress.state,
                    city: shippingAddress.city,
                    zipCode: shippingAddress.zipCode,
                    addressType: 'shippingAddress',
                    userinfo: userinfo ? userinfo : Pickupstore
                },
                billingAddress: {
                    address: addressType.address,
                    country: "Saudi Arabia",
                    state: addressType.state,
                    city: addressType.city,
                    zipCode: addressType.zipCode,
                    addressType: 'billingAddress',
                    userinfo: userinfo ? userinfo : Pickupstore
                }

            }),
        })
            .then(handleFetchErrors)
            .then((dataStoreList) => {
               
                // setStoreList(dataStoreList.data.store);
                localStorage.setItem('shippingAddress', dataStoreList.data.shippingAddress._id);
                localStorage.setItem('billingAddress', dataStoreList.data.billingAddress._id);
                localStorage.setItem("data", "door")
                navigate('/paymentgate')

            })
            .catch(standardFetchHandlers.error)
    };

    const GetRigions = async (req, res, next) => {
        fetch(`${API_BASE_URL}/userAddress/region`, {
            ...fetchConfig,
            method: 'GET',
        })
            .then(handleFetchErrors)
            .then((dataStoreList) => {
                //console.log(dataStoreList.data.region, "hello code")
                setregion(dataStoreList.data.region)
            })
            .catch(standardFetchHandlers.error)
    }


    useEffect(() => {
        fetchAllCartProduct();
        fetchUserAllProfile();
        fetchCity();
        GetRigions()
        // fetchStoreList();
    }, []);
    useEffect(() => {
        fetchAllCartProduct();
        fetchStoreList();
    }, [selectedCity]);

    const handleChangeDoor = event => {
        setIsStorePickUpChecked(false);
        setIsDoorStepChecked(true);
    };
    const handleChangeStore = event => {
        setIsDoorStepChecked(false);
        setIsStorePickUpChecked(true);
    };


    const handleBillingForm = (event) => {
        setIsBillingShippingSame(!isBillingShippingSame);
    };



    const handleShippingInputs = (e) => {
        let name = e.target.name;
        let value = e.target.value;
    
        if (name === "state") {
            // Find the state name from the `region` array for shipping
            const selectedRegion = region.find(item => item._id === value);
            const selectedStateName = selectedRegion ? selectedRegion.title : '';
    
            setShippingAddress((prevState) => ({
                ...prevState,
                [name]: selectedStateName,
            }));
    
            fetch(`${API_BASE_URL}/userAddress/region/${value}`, {
                ...fetchConfig,
                method: 'GET',
            })
                .then(handleFetchErrors)
                .then((dataStoreList) => {
                    setShippingCities(dataStoreList?.data?.cities);
                    //setcities(dataStoreList?.data?.cities); // Set cities for shipping
                    setShippingAddress((prevState) => ({
                        ...prevState,
                        city: dataStoreList?.data?.cities[0]?.citieName, // Set the first city for shipping
                    }));
                })
                .catch(standardFetchHandlers.error);
        } else {
            setShippingAddress((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };
    

    
    const handleBillingInputs = (e) => {
        let name = e.target.name;
        let value = e.target.value;
    
        if (name === "state") {
            // Find the state name from the `region` array for billing
            // const selectedRegion = region.find(item => item._id === value);
            // const selectedStateName = selectedRegion ? selectedRegion.title : '';
    
            // setBillingAddress((prevState) => ({
            //     ...prevState,
            //     [name]: selectedStateName,
            // }));
           // console.log("Billing state selected:", value); // Log the selected state
            setBillingAddress((prevState) => ({
                ...prevState,
                [name]: value,
            }));
    
            fetch(`${API_BASE_URL}/userAddress/region/${value}`, {
                ...fetchConfig,
                method: 'GET',
            })
                .then(handleFetchErrors)
                .then((dataStoreList) => {
                    setBillingCities(dataStoreList?.data?.cities);
                    //setcities(dataStoreList?.data?.cities); // Set cities for billing
                    setBillingAddress((prevState) => ({
                        ...prevState,
                        city: dataStoreList?.data?.cities[0]?.citieName, // Set the first city for billing
                    }));
                })
                .catch(standardFetchHandlers.error);
        } else {
            setBillingAddress((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };
    

    const datahandlre = (event) => {
        setTelephone(event.target.value)
        // window.localStorage.setItemItem('phone',event.target.value)
    }


    const handleOptionChange = (event) => {
        setSelectedOptions(event.target.value)
    }


    const userinfoHandler = (event) => {
        setuserinfo((prevstate) => ({
            ...prevstate,
            [event.target.name]: event.target.value
        }));


        validationIndivaulaSchema
            .validateAt(event.target.name, { [event.target.name]: event.target.value })
            .then(() => {
                // If the field is valid, clear its error message.
                setErrors((prevErrors) => ({ ...prevErrors, [event.target.name]: '' }));
            })
            .catch((error) => {
                // If there's an error, update the error message for that field.
                setErrors((prevErrors) => ({ ...prevErrors, [event.target.name]: error.message }));
            });

    }

    const usercmpyHandler = (event) => {
        setuserinfo((prevstate) => ({
            ...prevstate,
            [event.target.name]: event.target.value
        }));


        validationCompanySchema
            .validateAt(event.target.name, { [event.target.name]: event.target.value })
            .then(() => {
                // If the field is valid, clear its error message.
                setErrors((prevErrors) => ({ ...prevErrors, [event.target.name]: '' }));
            })
            .catch((error) => {
                // If there's an error, update the error message for that field.
                setErrors((prevErrors) => ({ ...prevErrors, [event.target.name]: error.message }));
            });
    }

    const pickupstoreHandler = (event) => {
        const selectedCityKey = event.target.value;
        const selectedCityValue = event.target.options[event.target.selectedIndex].text;

        // Uncomment this line to set the selectedCity state
        // setSelectedCity({ city: selectedCityValue, id: selectedCityKey });

        setPickupstore({ ...Pickupstore, city: selectedCityValue });

        if (selectedCityKey) { // Make sure 'selectedCity' is correctly set
            fetch(`${API_BASE_URL}/city/storeList`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    cityId: selectedCityKey, // Use 'selectedCity.id' instead of 'event.target.value'
                })
            })
                .then(handleFetchErrors)
                .then((dataStoreList) => {
                    
                    setStoreList(dataStoreList.data.store);
                })
                .catch(standardFetchHandlers.error);
        }
    }

    const pickupStateHandler = (event) => {
        const selectedCityValue = event.target.options[event.target.selectedIndex].text;
    
        setPickupstore({ state: selectedCityValue, city: '', store: '' });

        
    }

    const storepinckup = () => {
        if (selectedOptions === "Individual") {
            if (!userinfo.firstname || !userinfo.lastname || !userinfo.phonenumber) {
                toast.error('PERSONAL INFORMATION: All fields are required');
                return; // Exit the function if validation fails
            }
        } else if (selectedOptions === "Company") {
            if (!userinfo.companyname || !userinfo.taxnumber || !userinfo.phonenumber) {
                toast.error('COMPANY INFORMATION: All fields are required');
                return; // Exit the function if validation fails
            }
        }

        if (!Pickupstore.state || !Pickupstore.city || !Pickupstore.store) {
            toast.error('PICKUP STORE: All fields are required');
            return; // Exit the function if validation fails
        }

        // If validation passes, make the API call
        fetch(`${API_BASE_URL}/userAddress/pickup`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify({
                state: Pickupstore.state,
                city: Pickupstore.city,
                store: Pickupstore.store,
                firstname: userinfo.firstname,
                lastname: userinfo.lastname,
                phonenumber: userinfo.phonenumber,
                companyname: userinfo.companyname,
                taxnumber: userinfo.taxnumber
            }),
        })
            .then(handleFetchErrors)
            .then((dataStoreList) => {
                
                window.localStorage.setItem('pickupstoreId', dataStoreList.data.data._id,);
                if (dataStoreList.data.data._id) {
                    window.localStorage.setItem("data", "pickup_to_store")
                    window.localStorage.setItem("pickupstoreId", dataStoreList.data.data._id)
                    navigate('/paymentgate');
                }

            })
            .catch(standardFetchHandlers.error);
    };


    const storehandler = (event) => {
        const selectedCityKey = event.target.value;
        const selectedCityValue = event.target.options[event.target.selectedIndex].text;
        setPickupstore((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.options[event.target.selectedIndex].text
        }));

    }

    return (
        <div className={style.main_wrapper}>
            <div className={style.checkout_cart}>
                <h1>Checkout</h1>
                <p>{allCartProduct.length} item(s) in Cart</p>
            </div>
            <div className={style.checkout_cart_coupancode}>
                <div className={style.checkout_cart_heading}>
                    <img src="/assets/Group 569 (1).png" alt="" />
                    <span>Have a coupon? <h4>Click here to enter your code</h4></span>
                </div>
                <div>
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
            <div className={style.checkout_cart_customer_product}>
                <div className={style.checkout_cart_customer_products}>
                    <div className={style.checkout_cart_customer}>
                        <span><i class="fa-solid fa-check"></i> <h3>Customer</h3></span>
                        <div className={style.checkout_form_box}>
                            <h4>{userProfileInfo.email_address}</h4>
                        </div>
                    </div>
                    <div className={style.checkout_cart_gray_box}>
                        <span>2 <p>Pickup from store or indicate shipping option</p></span>
                        <div>
                            <h5>Personal information</h5>
                        </div>
                        <div className={style.pernol_info_data}>
                            <div >
                                <input type='radio'
                                    className={style.custom_checkbox}
                                    id='Persnalinformation'
                                    value="Individual"
                                    checked={selectedOptions === 'Individual'}
                                    onChange={handleOptionChange} />
                                <label htmlFor="Persnalinformation">Order as an Individual</label>
                            </div>
                            <div>
                                <input type='radio'
                                    className={style.custom_checkbox}
                                    id='company'
                                    value="company"
                                    checked={selectedOptions === 'company'}
                                    onChange={handleOptionChange}
                                />

                                <label htmlFor="company">Order as a Company</label>
                            </div>
                        </div>
                        {
                            selectedOptions == "Individual" ? (
                                <div className={style.input_fileds}>
                                    <div>
                                        {/* <div className={style.input_filed_wrap}>
                                    <label for="passport">Iqama No/Passport No</label><span style={{color:"red"}}>*</span>
                                    </div>
                                    <input type="text" id="passport" placeholder="Iqama No/Passport No" name="passport" /> */}

                                        <div className={style.input_filed_wrap}>
                                            <label for="fname">First Name</label><span style={{ color: "red" }}>*</span>
                                        </div>
                                        <input type="text" id="fname" value={userinfo.firstname} placeholder="First Name" name="firstname" onChange={userinfoHandler} />
                                        {errors.firstname}
                                    </div>
                                    <div>
                                        <div className={style.input_filed_wrap}>
                                            <label for="lname">Last Name </label><span style={{ color: "red" }}>*</span>
                                        </div>
                                        <input type="text" id="lname" value={userinfo.lastname} placeholder="Last Name" name="lastname" onChange={userinfoHandler} />
                                        {errors.lastname}
                                    </div>
                                </div>

                            ) : (
                                <div className={style.input_fileds}>
                                    <div>
                                        <div className={style.input_filed_wrap}>
                                            <label for="fname"> Company Name </label><span style={{ color: "red" }}>*</span>
                                        </div>
                                        <input type="text" id="companyname" placeholder="Company Name" name="companyname" onChange={usercmpyHandler} />
                                        {errors.companyname}
                                    </div>
                                    <div>
                                        <div className={style.input_filed_wrap}>
                                            <label for="lname">Tax Identification Number  </label><span style={{ color: "red" }}>*</span>
                                        </div>
                                        <input type="text" id="lname" placeholder="Tax Identification Number" name="taxnumber" onChange={usercmpyHandler} />
                                        {errors.taxnumber}
                                    </div>

                                </div>
                            )
                        }

                        <div className={style.address_name_content}>
                            <div className={style.input_filed_wrap}>
                                <label>Phone Number</label><span style={{ color: "red" }}>*</span>
                            </div>
                            <input type="text" placeholder="Enter the PhoneNumber" value={userinfo.phonenumber} name="phonenumber" onChange={userinfoHandler} />
                            {errors.phonenumber}
                        </div>

                        {/* <h5>Personal information</h5>
                        <div className={style.input_fileds}>
                            <div>
                                <label for="fname">First Name:</label>
                                <input type="text" id="fname" value={userProfileInfo.first_name} placeholder="First Name" name="firstname" />
                            </div>
                            <div>
                                <label for="lname">Last Name:</label>
                                <input type="text" id="lname" value={userProfileInfo.last_name} placeholder="Last Name" name="lastname" />
                            </div>
                        </div> */}
                        {/* <div className={style.input_filed_box}>
                            <label for="cname">Company Name (Optional)</label>
                            <input type="text" id="cname" placeholder="Company Name (Optional)" name="companyname" />
                        </div>
                        <div className={style.input_filed_box}>
                            <label for="phoneno">Phone Number</label>
                            <input type="text" id="phoneno" value={userProfileInfo.phone_number} placeholder="Phone Number" name="phonenumber" onChange={datahandlre} />
                        </div> */}
                        <div className={style.custom_radio_wrap}>
                            <h3>Shipping method</h3>
                            <div className={style.shipping_method}>
                                <form >
                                    <div className={style.form_group}>
                                        <div className={style.comment_form_cookies_consent} >
                                            {

                                                <input id={style.wp_comment_cookies_consent}
                                                    name="wp-comment-cookies-consent" type="radio" onChange={handleChangeStore} checked={isStorePickUpChecked} value="yes" />


                                            }
                                            <h6 className={style.label_text}>Pickup from Store</h6>
                                        </div>
                                    </div>
                                    {
                                        isStorePickUpChecked === true ? (
                                            <>

                                                <div className={style.select_boxes}>
                                                    <input type="hidden" name="country" value={'saudi arabia'} />
                                                    <div className={style.country_code}>
                                                        <div>
                                                            <h6> State/Province </h6>
                                                        </div>

                                                        <div className={style.select}>

                                                        <select
                                                        value={region.find(item => item.title === Pickupstore.state)?._id || '1'} // default to '1' if state not found
                                                        name='state'
                                                        onChange={(event) => {
                                                            
                                                            pickupStateHandler(event); // Call the handler function
                                                        }}
                                                                >
                                                                <option value="">State</option>
                                                                {region.length > 0 ? (
                                                                    region.map((item) => (
                                                                        <option key={item?._id} value={item?._id}>
                                                                            {item?.title}
                                                                        </option>
                                                                    ))
                                                                ) : null}

                                                            </select>
                                                        </div>
                                                      

                                                    </div>
                                                </div>
                                                <div className={style.select_boxes}>
                                                    <div className={style.country_code}>
                                                        <div>
                                                            <h6>City </h6>
                                                        </div>
                                                        <div className={style.select}>

                                                            <select
                                                                // value={Pickupstore.city}
                                                                name='city'
                                                                onChange={pickupstoreHandler}


                                                            >
                                                                <option value="">City</option>
                                                                {city.length > 0 ? (
                                                                    city.map((item) => (
                                                                        <option key={item._id} value={item._id}>
                                                                            {item.title}
                                                                        </option>
                                                                    ))
                                                                ) : null}
                                                            
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={style.select_boxes}>
                                                    <div>
                                                        <div>
                                                            <h6>Select Store</h6>
                                                        </div>
                                                        <div className={style.select}>
                                                            <select name='store' onChange={storehandler}>
                                                                <option value="">Select Store</option>
                                                                {storeList.length > 0 ? (
                                                                    storeList.map((data) => (
                                                                        <option key={data._id} value={data._id}>
                                                                            {data.store}
                                                                        </option>
                                                                    ))
                                                                ) : null}

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )
                                    }
                                </form>
                            </div>
                            <div className={style.shipping_method}>
                                <form>
                                    <div className={style.form_group}>
                                        <div className={style.comment_form_cookies_consent} >
                                            {
                                                isDoorStepChecked == true ?

                                                    <input id={style.wp_comment_cookies_consent} onChange={handleChangeDoor} name="wp-comment-cookies-consent" checked={isDoorStepChecked} type="radio" value="yes" />
                                                    :

                                                    <input id={style.wp_comment_cookies_consent} onChange={handleChangeDoor} name="wp-comment-cookies-consent" checked={isDoorStepChecked} type="radio" value="yes" />

                                            }
                                            <h6 className={style.label_text}>Deliver at your Door Step</h6>
                                        </div>
                                    </div>
                                    {
                                        isDoorStepChecked == true ?
                                            <>
                                                <div className={style.doorStep} >
                                                    <div className={style.deliver_text}>
                                                        <h4>default  address</h4>
                                                        
                                                        <h4 onClick={resetUserAddressInfo}>Add new Address</h4>
                                                    </div>
                                                    <div className={style.select_boxes}>
                                                        <input type="hidden" name="country" value={'saudi arabia'} />
                                                        <div className={style.country_code}>
                                                            <div>
                                                                <h6> State/Province </h6>
                                                            </div>
                                                       
                                                            <div className={style.select}>
                                                                <select
                                                                    value={region.find(item => item.title === shippingAddress.state)?._id || '1'} // default to '1' if state not found
                                                                    name='state'
                                                                    onChange={(event) => {
                                                                        
                                                                        handleShippingInputs(event); // Call the handler function
                                                                    }}
                                                                >
                                                                    <option value="1">State/Province</option>
                                                                    {region.length > 0 ? (
                                                                        region.map((item) => (
                                                                            <option key={item._id} value={item._id}>
                                                                                {item.title}
                                                                            </option>
                                                                        ))
                                                                    ) : null}
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className={style.select_boxes}>
                                                        <div className={style.country_code}>
                                                            <div>
                                                                <h6>City </h6>
                                                            </div>
                                                            <div className={style.select}>

                                                                <select
                                                                    value={shippingAddress.city}
                                                                    name='city'
                                                                    onChange={handleShippingInputs}
                                                                >
                                                                    <option value="">City</option>

                                                                    {city.length > 0 ? (
                                                                        city.map((item) => (
                                                                            <option key={item._id} value={item._id}>
                                                                                {item.title}
                                                                            </option>
                                                                        ))
                                                                    ) : null}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className={style.country_code}>
                                                            <div className={style.select_box_heading}>
                                                                <h1> Postal Code</h1>
                                                            </div>
                                                            <div className={style.select_box_Cart2}>
                                                                <input type="text" placeholder="Postal Code"
                                                                    name="zipCode"
                                                                    value={shippingAddress.zipCode}
                                                                    onChange={handleShippingInputs}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style.address_name_content}>
                                                        <h1>Building Number </h1>
                                                        <input type="text" placeholder="Building Number"
                                                            name="buildingNumber"
                                                            value={shippingAddress.buildingNumber}
                                                            onChange={handleShippingInputs}
                                                        />

                                                    </div>
                                                    <div className={style.address_name_content}>
                                                        <h1>Address</h1>
                                                        <input type="text" placeholder="Address"
                                                            name="address"
                                                            value={shippingAddress.address}
                                                            onChange={handleShippingInputs}
                                                        />

                                                    </div>
                                                    <div className={style.checkbox_wrap}>
                                                        <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" checked={isBillingShippingSame} type="checkbox"
                                                            onChange={handleBillingForm} value={shippingAddress.addressType}
                                                        />
                                                        <label for="wp-comment-cookies-consent">My billing address is the same as my shipping address
                                                        </label>
                                                    </div>
                                                </div>

                                                {!isBillingShippingSame && (
                                                    <form>
                                                        <>
                                                            <div className={style.doorStep} >
                                                                <h2>Billing Address</h2>
                                                                <div className={style.select_boxes}>
                                                                    <input type="hidden" name="country" value={'saudi arabia'} />
                                                                    <div className={style.country_code}>
                                                                        <div>
                                                                            <h6> State/Province </h6>
                                                                        </div>
                                                          
                                                                        <div className={style.select}>
                                                                            {/* <select
                                                                                value={billingAddress.state}
                                                                                name='state'
                                                                                onChange={handleBillingInputs}
                                                                            >
                                                                                <option value="1">State/Province</option>
                                                                                {region.length > 0 ? (
                                                                                    region.map((item) => (
                                                                                        <option key={item._id} value={item._id}>
                                                                                            {item.title}
                                                                                        </option>
                                                                                    ))
                                                                                ) : null}

                                                                            </select> */}
                                                                            <select
    value={region.find(item => item.title === billingAddress.state)?._id || '1'} // Default value is '1' (State/Province) if it's not set
    name='state'
    onChange={handleBillingInputs}
>
    <option value="1">State/Province</option>
    {region.length > 0 ? (
        region.map((item) => (
            <option key={item._id} value={item._id}>
                {item.title}
            </option>
        ))
    ) : null}
</select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={style.select_boxes}>
                                                                    <div className={style.country_code}>
                                                                        <div>
                                                                            <h6>City </h6>
                                                                        </div>

                                                                        <div className={style.select}>

                                                                        <select
                                                                    value={billingAddress.city}
                                                                    name='city'
                                                                    onChange={handleBillingInputs}
                                                                >
                                                                    <option value="">City</option>

                                                                    {city.length > 0 ? (
                                                                        city.map((item) => (
                                                                            <option key={item._id} value={item._id}>
                                                                                {item.title}
                                                                            </option>
                                                                        ))
                                                                    ) : null}
                                                                </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className={style.country_code}>
                                                                        <div className={style.select_box_heading}>
                                                                            <h1> Postal Code</h1>
                                                                        </div>
                                                                        <div className={style.select_box_Cart2}>
                                                                            <input type="text" placeholder="Postal Code"
                                                                                name="zipCode"
                                                                                value={billingAddress.zipCode}
                                                                                onChange={handleBillingInputs}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className={style.address_name_content}>
                                                                    <h1>Building Number </h1>
                                                                    <input type="text" placeholder="Building Number"
                                                                        name="buildingNumber"
                                                                        value={billingAddress.buildingNumber}
                                                                        onChange={handleBillingInputs}
                                                                    />

                                                                </div>
                                                                <div className={style.address_name_content}>
                                                                    <h1>Address</h1>
                                                                    <input type="text" placeholder="Address"
                                                                        name="address"
                                                                        value={billingAddress.address}
                                                                        onChange={handleBillingInputs}
                                                                        required
                                                                    />

                                                                </div>


                                                            </div>


                                                        </>

                                                    </form>
                                                )}
                                            </> : ``
                                    }
                                </form>
                                {/* fomm form form form foerm fomr form form form form form form frm form form form form form form form form */}

                            </div>
                            <div className={style.order_method}>
                                <h3>Order comments</h3>
                                <input type="text" id="lname" placeholder="Leave your comments (optional)" name="lname" />
                                {/* <Link to="/Paymentgate"> */}
                                <button onClick={(event) => { isStorePickUpChecked ? storepinckup() : handleSubmit() }}>Continue</button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className={style.checkout_cart_gray_box1}>
                        <span>3<p>Payment</p></span>
                    </div>
                </div>
                <div className={style.checkout_order_summery}>
                    <h2>Order Summary</h2>
                    {
                        allCartProduct.map((allproductByFilter) => {
                            return (
                                <div className={style.checkout_items_products}>
                                    <div className={style.checkout_items_oil_filters}>
                                        <div>
                                            <img src={allproductByFilter.thumbnail ? `${IMG_URL}/${allproductByFilter.thumbnail}` : `/assets/Rectangle 114 (1).png`} alt="" />
                                        </div>
                                        <div >
                                            <div>
                                                <h5>{allproductByFilter.title}</h5>
                                            </div>
                                            <div>
                                                <h4>{allproductByFilter.sku}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h5>{allproductByFilter.price.toFixed(2)}  <span style={{marginLeft: '4px'}}>SAR</span></h5>
                                        </div>
                                        <div>
                                            <h4>{allproductByFilter.quantity} item</h4>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={style.order_summary_section}>
                        <div className={style.order_summary}>
                            <div className={style.order_summary_lists}>
                                <p>Sub Total:</p>
                                {<p> Discount:</p>} 
                                <p>VAT(15%):</p>
                                <h2>Total:</h2>
                            </div>

                            { <div className={style.order_summary_lists1}>
                                <p>{cartOrderSummary.subTotal} SAR</p>
                                <p>{cartOrderSummary.discount} SAR</p>
                                <p>{cartOrderSummary.vat} SAR</p>
                                <h2>{cartOrderSummary.total} SAR</h2>
                            </div>}
                            


                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


