import React from 'react'
import style from "./about-us.module.css";
import HeroImage from '../../components/hero-image/HeroImage';
import Pagination from "../../components/paginations/Pagination"
import Image from "../../assets/images/aboutUsHeroImage.png";
import BottomHeroImage from "../../assets/images/BottomAbooutUs.png";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import { CleaningServices } from '@mui/icons-material';
import { CircularProgress, Grid, Box } from '@mui/material';
import { IMG_URL } from '../../config/config';
import CustomPagination from '../../components/paginations/customPagination';
export default function AbouttUs() {
  const { t } = useTranslation();

  const appURL = 'https://admin-anycarpart.go-demo.com/api/v1/settings/aboutUs'

  const [aboutUs, setAboutUs] = useState({});


  useEffect(() => {
    fetch(`${appURL}`)
      .then((response) => response.json())
      .then((data) => {
        setAboutUs(data.data.aboutUs[0]);
        
      })
      .catch((error) => console.log(error));
  }, []);

  //   const aboutUsHtml = () => {
  //     if (aboutUs?.data?.aboutUs && aboutUs.data.aboutUs.length > 0) {
  //       const aboutUsData = aboutUs.data.aboutUs[0];
  //       return { __html: aboutUsData.aboutUs };
  //     }
  //     return { __html: '' };
  //   };
  const baseImageUrl = "https://admin-anycarpart.go-demo.com/aboutus/";


  const [isLoading, setIsLoading] = useState(true); // State variable to track loading status

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
 
  return (
    // <div dangerouslySetInnerHTML={aboutUsHtml()}></div>
    <>


      <HeroImage HeroImage={Image} Heading={t('AboutUsHeading')} />
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <Grid item>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div className={style.main_wrapper}>
          <div className={style.about_us_section}>
            <div className={style.about_us_text}>
              <h4>{t('AboutUsHeading')}</h4>
              <h1>{t('AboutUsTitle1')}</h1>
              {/* <p>{t('AboutUsText1')}</p>  */}
              <div dangerouslySetInnerHTML={{ __html: aboutUs?.section1?.desc }} />
              {/* <p>{aboutUs?.section1?.desc}</p> */}
            </div>

            <div className={style.about_us_img_wrapper}>
              <img src={`${IMG_URL}/aboutus/${aboutUs?.section1?.imageUrl}`} alt="section1" srcset="" />
              {/* <img src="/assets/about-us.png" alt="" srcset="" /> */}
              <img style={{ marginTop: '50px' }} className={`${style.border_image} ${style.arabic_border_image}`} src="/assets/Rectangle 105.svg" alt="" />
            </div>
          </div>

          <div className={style.about_us_section1}>
            <div className={style.about_us_img_wrapper}>
              {/* <img src="/assets/about_us.png" alt="" srcset="" /> */}
              <img src={`${IMG_URL}/aboutus/${aboutUs?.section2?.imageUrl}`} alt="section2" />
              {/* <img className={`${style.border_image1} ${style.arabic_border_image1}`} src="/assets/Rectangle 105.svg" alt="" /> */}
              <img className={`${style.border_image1} ${style.arabic_border_image1}`} src="/assets/Rectangle 105.svg" alt="" />
            </div>
            <div className={style.about_us_text}>
              <h4 style={{ marginTop: '10%' }}>{t('AboutUsHeading')}</h4>
              <h1>{t('AboutUsTitle2')}</h1>
              {/* <p>{t('AboutUsText2')}</p> */}
              <div dangerouslySetInnerHTML={{ __html: aboutUs?.section2?.desc }} />
            </div>
          </div>

          <section className={style.retailers_details_section_wrapper}>
            <p>{t('AboutUsHeading')}</p>
            <h1>{t(aboutUs?.section3?.title)}</h1>
            <h4>{t(aboutUs?.section3?.desc)}</h4>
            {/* <h1>{t('AboutUsService')}</h1> */}

            {/* <h4>Sutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum.  </h4> */}
            {/* <div dangerouslySetInnerHTML={{ __html: aboutUs?.section3?.desc }} /> */}


            <div className={style.retailers_card_section}>
              <article className={style.retailers_card}>
                {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.7059 1.64697V18.1176H47.7647V8.23521H31.2941V18.1176H24.7059V8.23521H8.23529V18.1176H3.29412V1.64697H0V54.3529H8.23529H24.7059H31.2941H47.7647H56V1.64697H52.7059ZM34.5882 11.5293H37.8824V14.8234H41.1765V11.5293H44.4706V18.1176H34.5882V11.5293ZM11.5294 11.5293H14.8235V14.8234H18.1176V11.5293H21.4118V18.1176H11.5294V11.5293ZM8.23529 21.4117H24.7059H31.2941H47.7647H52.7059V34.5882H49.4118V24.7058H39.5294V34.5882H36.2353V24.7058H19.7647V34.5882H16.4706V24.7058H6.58824V34.5882H3.29412V21.4117H8.23529ZM46.1176 34.5882H42.8235V27.9999H46.1176V34.5882ZM29.6471 31.294V27.9999H32.9412V34.5882H23.0588V27.9999H26.3529V31.294H29.6471ZM13.1765 34.5882H9.88235V27.9999H13.1765V34.5882ZM11.5294 51.0587V44.4705H14.8235V47.7646H18.1176V44.4705H21.4118V51.0587H11.5294ZM34.5882 51.0587V44.4705H37.8824V47.7646H41.1765V44.4705H44.4706V51.0587H34.5882ZM47.7647 51.0587V41.1764H31.2941V51.0587H24.7059V41.1764H8.23529V51.0587H3.29412V37.8823H6.58824H16.4706H19.7647H36.2353H39.5294H49.4118H52.7059V51.0587H47.7647Z" fill="#F86B1F" />
</svg> */}

                <img src={`${IMG_URL}/aboutus/${aboutUs?.section3?.image_Url1}`} alt="image1" />

                <h3>{t(aboutUs?.section3?.card_details[0]?.title)}</h3>
                <p>{t(aboutUs?.section3?.card_details[0]?.description)}</p>
                {/* <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p> */}

              </article>


              <article className={style.retailers_card}>
                {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_540_931)">
<path d="M53.2656 21.0875H48.9708V19.2136C48.9708 8.49877 38.5189 0 28 0C17.4999 0 7.0292 8.47941 7.0292 19.2136V21.0875H2.73438C1.22664 21.0875 0 22.3141 0 23.8219V35.6927C0 37.2004 1.22664 38.427 2.73438 38.427H7.02953V41.8709C7.02953 47.724 11.7908 52.4858 17.6433 52.4858H21.9661V53.2656C21.9661 54.7734 23.1927 56 24.7005 56H31.2995C32.8073 56 34.0339 54.7734 34.0339 53.2656V48.4239C34.0339 46.9162 32.8073 45.6895 31.2995 45.6895H24.7005C23.1927 45.6895 21.9661 46.9162 21.9661 48.4239V49.2045H17.6433C13.6001 49.2045 10.3108 45.9147 10.3108 41.8709V41.063H14.6053C16.113 41.063 17.3397 39.8363 17.3397 38.3286V21.1859C17.3397 19.6782 16.113 18.4516 14.6053 18.4516H10.3342C10.8267 10.4182 19.0248 3.28125 28 3.28125C36.9752 3.28125 45.1733 10.4182 45.6659 18.4516H41.3948C39.8871 18.4516 38.6605 19.6782 38.6605 21.1859V38.3286C38.6605 39.8363 39.8871 41.063 41.3948 41.063H46.2365C47.7112 41.063 48.9163 39.8893 48.9684 38.4269H53.2656C54.7734 38.4269 56 37.2003 56 35.6926V23.8219C56 22.3141 54.7734 21.0875 53.2656 21.0875ZM25.2474 48.9708H30.7526V52.7188H25.2474V48.9708ZM3.28125 24.3687H7.0292V35.1458H3.28125V24.3687ZM14.0583 37.7818H10.3108C10.3108 32.963 10.3105 26.5873 10.3105 21.7329H14.0584V37.7818H14.0583ZM45.6895 37.7818H41.9416V21.7328H45.6895V37.7818ZM52.7188 35.1458H48.9708C48.9708 34.1401 48.9708 25.8101 48.9708 24.3687H52.7188V35.1458Z" fill="#F86B1F" />
</g>
<defs>
<clipPath id="clip0_540_931">
<rect width="56" height="56" fill="white" />
</clipPath>
</defs>
</svg> */}                <img src={`${IMG_URL}/aboutus/${aboutUs?.section3?.image_Url2}`} alt="image2" />


                {/* <h3>{t('AboutUsServiceName')}</h3> */}
                <h3>{t(aboutUs?.section3?.card_details[1]?.title)}</h3>
                <p>{t(aboutUs?.section3?.card_details[1]?.description)}</p>

                {/* <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p> */}

              </article>

              <article className={style.retailers_card}>

                {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_540_945)">
<path d="M28.0002 0C15.2099 0 4.8042 10.4057 4.8042 23.196C4.8042 29.3918 7.21698 35.2168 11.5981 39.5981L28.0002 56.0001L44.4022 39.5981C48.7834 35.2169 51.1962 29.3918 51.1962 23.196C51.1962 10.4057 40.7905 0 28.0002 0V0ZM42.0819 37.2777L28.0002 51.3595L13.9185 37.2777C10.1571 33.5163 8.08555 28.5153 8.08555 23.1959C8.08555 12.2151 17.0193 3.28135 28.0002 3.28135C38.9811 3.28135 47.9148 12.2151 47.9148 23.196C47.9148 28.5153 45.8434 33.5163 42.0819 37.2777Z" fill="#F86B1F" />
<path d="M28.0004 6.56274C18.8288 6.56274 11.3672 14.0243 11.3672 23.1959C11.3672 32.3676 18.8288 39.8292 28.0004 39.8292C37.172 39.8292 44.6335 32.3675 44.6335 23.1959C44.6337 14.0243 37.172 6.56274 28.0004 6.56274ZM39.5821 16.5577H22.1447V11.1986C23.9138 10.3317 25.9011 9.8441 28.0004 9.8441C32.9479 9.8441 37.2755 12.549 39.5821 16.5577ZM41.3523 23.1959C41.3523 24.355 41.2035 25.4799 40.9247 26.5529H22.1447V19.8391H40.9246C41.2035 20.9121 41.3523 22.0369 41.3523 23.1959ZM14.6485 23.1959C14.6485 19.3653 16.2706 15.9068 18.8633 13.4697V32.9222C16.2706 30.4851 14.6485 27.0267 14.6485 23.1959ZM28.0004 36.548C25.9011 36.548 23.9138 36.0604 22.1447 35.1933V29.8342H39.5821C37.2755 33.8429 32.9479 36.548 28.0004 36.548Z" fill="#F86B1F" />
</g>
<defs>
<clipPath id="clip0_540_945">
<rect width="56" height="56" fill="white" />
</clipPath>
</defs>
</svg> */}


                <img src={`${IMG_URL}/aboutus/${aboutUs?.section3?.image_Url3}`} alt="image3" />


                {/* <h3>{t('AboutUsServiceName')}</h3> */}
                <h3>{t(aboutUs?.section3?.card_details[2]?.title)}</h3>
                <p>{t(aboutUs?.section3?.card_details[2]?.description)}</p>
                {/* <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p> */}


              </article>

              <article className={style.retailers_card}>
                {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5662 11.1133L15.2461 8.79297L9.84362 14.1954L7.72241 12.0742L5.40234 14.3945L9.84362 18.8358L17.5662 11.1133Z" fill="#F86B1F" />
<path d="M36.3125 21.4375V11.5938H22.8511C22.0525 6.03564 17.2598 1.75 11.4844 1.75C5.15189 1.75 0 6.90189 0 13.2344C0 16.3563 1.25278 19.1907 3.28125 21.2629V44.4062H9.84375V50.9688H16.6884C17.3657 52.8783 19.1893 54.25 21.3281 54.25C23.467 54.25 25.2906 52.8783 25.9678 50.9688H39.8759C40.5532 52.8783 42.3768 54.25 44.5156 54.25C46.6545 54.25 48.4781 52.8783 49.1553 50.9688H56V28.9611L48.4764 21.4375H36.3125ZM11.4844 5.03125C16.0076 5.03125 19.6875 8.71117 19.6875 13.2344C19.6875 17.7576 16.0076 21.4375 11.4844 21.4375C6.96117 21.4375 3.28125 17.7576 3.28125 13.2344C3.28125 8.71117 6.96117 5.03125 11.4844 5.03125ZM6.5625 23.6091C8.05514 24.3201 9.72398 24.7188 11.4844 24.7188C17.2598 24.7188 22.0525 20.4331 22.8511 14.875H33.0312V41.125H6.5625V23.6091ZM21.3281 50.9688C20.4235 50.9688 19.6875 50.2328 19.6875 49.3281C19.6875 48.4235 20.4235 47.6875 21.3281 47.6875C22.2328 47.6875 22.9688 48.4235 22.9688 49.3281C22.9688 50.2328 22.2328 50.9688 21.3281 50.9688ZM44.5156 50.9688C43.611 50.9688 42.875 50.2328 42.875 49.3281C42.875 48.4235 43.611 47.6875 44.5156 47.6875C45.4203 47.6875 46.1562 48.4235 46.1562 49.3281C46.1562 50.2328 45.4203 50.9688 44.5156 50.9688ZM49.1553 47.6875C48.4781 45.7779 46.6545 44.4062 44.5156 44.4062C42.3768 44.4062 40.5532 45.7779 39.8759 47.6875H25.9678C25.2906 45.7779 23.467 44.4062 21.3281 44.4062C19.1893 44.4062 17.3657 45.7779 16.6884 47.6875H13.125V44.4062H36.3125V24.7188H39.5938V37.8438H52.7188V41.125H49.4375V44.4062H52.7188V47.6875H49.1553ZM52.7188 34.5625H42.875V24.7188H47.1173L52.7188 30.3202V34.5625Z" fill="#F86B1F" />
</svg> */}
                {/* 
<img src={aboutUs?.section3?.card_details[3]?.image_Url} alt="image3" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section3?.image_Url4}`} alt="image4" />


                {/* <h3>{t('AboutUsServiceName')}</h3> */}

                <h3>{t(aboutUs?.section3?.card_details[3]?.title)}</h3>
                <p>{t(aboutUs?.section3?.card_details[3]?.description)}</p>
                {/* <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p> */}

              </article>

              <article className={style.retailers_card}>
                {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.7059 1.64697V18.1176H47.7647V8.23521H31.2941V18.1176H24.7059V8.23521H8.23529V18.1176H3.29412V1.64697H0V54.3529H8.23529H24.7059H31.2941H47.7647H56V1.64697H52.7059ZM34.5882 11.5293H37.8824V14.8234H41.1765V11.5293H44.4706V18.1176H34.5882V11.5293ZM11.5294 11.5293H14.8235V14.8234H18.1176V11.5293H21.4118V18.1176H11.5294V11.5293ZM8.23529 21.4117H24.7059H31.2941H47.7647H52.7059V34.5882H49.4118V24.7058H39.5294V34.5882H36.2353V24.7058H19.7647V34.5882H16.4706V24.7058H6.58824V34.5882H3.29412V21.4117H8.23529ZM46.1176 34.5882H42.8235V27.9999H46.1176V34.5882ZM29.6471 31.294V27.9999H32.9412V34.5882H23.0588V27.9999H26.3529V31.294H29.6471ZM13.1765 34.5882H9.88235V27.9999H13.1765V34.5882ZM11.5294 51.0587V44.4705H14.8235V47.7646H18.1176V44.4705H21.4118V51.0587H11.5294ZM34.5882 51.0587V44.4705H37.8824V47.7646H41.1765V44.4705H44.4706V51.0587H34.5882ZM47.7647 51.0587V41.1764H31.2941V51.0587H24.7059V41.1764H8.23529V51.0587H3.29412V37.8823H6.58824H16.4706H19.7647H36.2353H39.5294H49.4118H52.7059V51.0587H47.7647Z" fill="#F86B1F" />
</svg> */}
                {/* ubdejbdjdbej */}

                {/* <img src={aboutUs?.section3?.card_details[4]?.image_Url} alt="image4" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section3?.image_Url5}`} alt="image5" />


                <h3>{t(aboutUs?.section3?.card_details[4]?.title)}</h3>
                <p>{t(aboutUs?.section3?.card_details[4]?.description)}</p>

                {/* <h3>{t('AboutUsServiceName')}</h3> */}
                {/* <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p> */}

              </article>

              <article className={style.retailers_card}>
                {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_540_1649)">
<path d="M53.2656 21.0875H48.9708V19.2136C48.9708 8.49877 38.5189 0 28 0C17.4999 0 7.0292 8.47941 7.0292 19.2136V21.0875H2.73438C1.22664 21.0875 0 22.3141 0 23.8219V35.6927C0 37.2004 1.22664 38.427 2.73438 38.427H7.02953V41.8709C7.02953 47.724 11.7908 52.4858 17.6433 52.4858H21.9661V53.2656C21.9661 54.7734 23.1927 56 24.7005 56H31.2995C32.8073 56 34.0339 54.7734 34.0339 53.2656V48.4239C34.0339 46.9162 32.8073 45.6895 31.2995 45.6895H24.7005C23.1927 45.6895 21.9661 46.9162 21.9661 48.4239V49.2045H17.6433C13.6001 49.2045 10.3108 45.9147 10.3108 41.8709V41.063H14.6053C16.113 41.063 17.3397 39.8363 17.3397 38.3286V21.1859C17.3397 19.6782 16.113 18.4516 14.6053 18.4516H10.3342C10.8267 10.4182 19.0248 3.28125 28 3.28125C36.9752 3.28125 45.1733 10.4182 45.6659 18.4516H41.3948C39.8871 18.4516 38.6605 19.6782 38.6605 21.1859V38.3286C38.6605 39.8363 39.8871 41.063 41.3948 41.063H46.2365C47.7112 41.063 48.9163 39.8893 48.9684 38.4269H53.2656C54.7734 38.4269 56 37.2003 56 35.6926V23.8219C56 22.3141 54.7734 21.0875 53.2656 21.0875ZM25.2474 48.9708H30.7526V52.7188H25.2474V48.9708ZM3.28125 24.3687H7.0292V35.1458H3.28125V24.3687ZM14.0583 37.7818H10.3108C10.3108 32.963 10.3105 26.5873 10.3105 21.7329H14.0584V37.7818H14.0583ZM45.6895 37.7818H41.9416V21.7328H45.6895V37.7818ZM52.7188 35.1458H48.9708C48.9708 34.1401 48.9708 25.8101 48.9708 24.3687H52.7188V35.1458Z" fill="#F86B1F" />
</g>
<defs>
<clipPath id="clip0_540_1649">
<rect width="56" height="56" fill="white" />
</clipPath>
</defs>
</svg> */}

                {/* <img src={aboutUs?.section3?.card_details[5]?.image_Url} alt="image5" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section3?.image_Url6}`} alt="image6" />

                <h3>{t(aboutUs?.section3?.card_details[5]?.title)}</h3>
                <p>{t(aboutUs?.section3?.card_details[5]?.description)}</p>

                {/* <h3>{t('AboutUsServiceName')}</h3>
<p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p> */}

              </article>


            </div>
          </section>



          <div className={style.benefits_section}>
            {/* <h2>{t('AboutUsSellerbecome')}</h2>
<h3>{t('AboutUsSellerBenefits')}</h3>
<p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p>  */}
            <h3>{t(aboutUs?.section4?.title)}</h3>
            <p>{t(aboutUs?.section4?.desc)}</p>
            {/* <div dangerouslySetInnerHTML={{ __html: aboutUs?.section3?.desc }} /> */}
          </div>


          <section className={style.top_categgories_card_section} >

            <div>

              {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
<path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
<path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
<path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
<path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
<path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
<path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
</svg> */}

              {/* <p>{t('AboutUsSellerBenefitsTitle1')}</p> */}
              {/* <img src={aboutUs?.section4?.card_details[0]?.image_url} alt="" />
 */}

              <img src={`${IMG_URL}/aboutus/${aboutUs?.section4?.image_url1}`} alt="image1" />

              <p>{t(aboutUs?.section4?.card_details[0]?.title)}</p>
            </div>
            <div>
              {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
<path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
<path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
<path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
<path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
<path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
<path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
<path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
<path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
<path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
<path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
</svg> */}


              {/* <img src={aboutUs?.section4?.card_details[1]?.image_url} alt="" /> */}

              <img src={`${IMG_URL}/aboutus/${aboutUs?.section4?.image_url2}`} alt="image2" />

              <p style={{fontFamily:"Neue Haas Grotesk Text Pro", fontWeight:'500', fontSize:'16px'}}>{t(aboutUs?.section4?.card_details[1]?.title)}</p>
              {/* <p>{t('AboutUsSellerBenefitsTitle2')}</p> */}
            </div>
            <div>
              {/* <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
<path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
<path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
</svg> */}


              {/* <p>{t('AboutUsSellerBenefitsTitle3')}</p> */}
              {/* <img src={aboutUs?.section4?.card_details[2]?.image_url} alt="" /> */}
              <img src={`${IMG_URL}/aboutus/${aboutUs?.section4?.image_url3}`} alt="image3" />

              <p>{t(aboutUs?.section4?.card_details[2]?.title)}</p>
            </div>
            <div>
              {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
<path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
<path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
<path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
<path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
<path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
<path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
</svg> */}
              {/* <p>{t('AboutUsSellerBenefitsTitle4')}</p> */}
              {/* <img src={aboutUs?.section4?.card_details[3]?.image_url} alt="" /> */}
              <img src={`${IMG_URL}/aboutus/${aboutUs?.section4?.image_url4}`} alt="image4" />

              <p>{t(aboutUs?.section4?.card_details[3]?.title)}</p>
            </div>
            <div>
              {/* <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
<path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
<path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
</svg> */}


              {/* <p>{t('AboutUsSellerBenefitsTitle5')}</p> */}
              {/* <img src={aboutUs?.section4?.card_details[4]?.image_url} alt="" />
 */}
              <img src={`${IMG_URL}/aboutus/${aboutUs?.section4?.image_url5}`} alt="image5" />

              <p>{t(aboutUs?.section4?.card_details[4]?.title)}</p>
            </div>

          </section>


          <div className={style.benefits_lists}>
            <div className={style.benefits_ul_lists}>
              <ul>
                <div className={`${style.list_style_box} ${style.arabic_list_style_box}`}>
                  {/* <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="8" height="8" fill="#F86B1F" />
</svg> */}
                  {/* <li>Dignissim eu nam neque nunc volutpat.</li> */}

                  <div dangerouslySetInnerHTML={{ __html: aboutUs?.section4?.bullets }} />

                  {/* <li>{t(aboutUs?.section4?.pointData[0]?.points)}</li> */}
                </div>

                <div className={`${style.list_style_box} ${style.arabic_list_style_box}`}>
                  {/* <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="8" height="8" fill="#F86B1F" />
</svg> */}
                  {/* <li>Dignissim eu nam neque nunc volutpat.</li> */}
                  {/* <li>{t(aboutUs?.section4?.pointData[1]?.points)}</li> */}
                </div>
                <div className={`${style.list_style_box} ${style.arabic_list_style_box}`}>
                  {/* <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="8" height="8" fill="#F86B1F" />
</svg> */}
                  {/* <li>Dignissim eu nam neque nunc volutpat.</li> */}
                  {/* <li>{t(aboutUs?.section4?.pointData[2]?.points)}</li> */}
                </div>
                <div className={`${style.list_style_box} ${style.arabic_list_style_box}`}>
                  {/* <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="8" height="8" fill="#F86B1F" />
</svg> */}
                  {/* <li>Dignissim eu nam neque nunc volutpat.</li> */}
                  {/* <li>{t(aboutUs?.section4?.pointData[3]?.points)}</li> */}
                </div>
                <div className={`${style.list_style_box} ${style.arabic_list_style_box}`}>
                  {/* <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="8" height="8" fill="#F86B1F" />
</svg> */}
                  {/* <li>Dignissim eu nam neque nunc volutpat.</li> */}
                  {/* <li>{t(aboutUs?.section4?.pointData[4]?.points)}</li> */}
                </div>
              </ul>
              <button>{t(aboutUs?.section4?.button)}</button>
            </div>
            <div className={style.benifit_section_wrapper_image}>
              {/* <img src="/assets/become_buyer.png" alt="" /> */}
          
              <img src={`${IMG_URL}/aboutus/${aboutUs?.section4?.image_bullet}`} alt=" Bullet image" />

            </div>
          </div>
          <section className={style.benifit_section_wrapper}>
            <div className={style.benefits_section}>
              <h2>{t('AboutUsHeading')}</h2>
              <h3>{t('AboutUsCustomerSays')}</h3>
              <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum.</p>
            </div>
            <div className={style.custmer_list}>
              <div className={style.custmer_list1}>
                <img className={style.quotes_image} src="/assets/Group 599.svg" alt="" />
                <div className={style.custmer_img_box}>
                  <div>
                    <img src="/assets/Ellipse 1.png" alt="" />
                  </div>
                  <div>
                    <h3>{t('AboutUsName1')}</h3>
                    <h4>{t('AboutUsPosition1')}</h4>

                  </div>
                </div>

                <p>“Diam eu aliquam eget pretium metus. Vulputate arcu nibh morbi suspendisse. Ut tortor suspendisse cras eget condimentum sociis ut quis dui. Turpis maecenas neque, varius mattis feugiat.” </p>
              </div>
              <div className={style.custmer_list1}>
                <img className={style.quotes_image} src="/assets/Group 599.svg" alt="" />
                <div className={style.custmer_img_box}>
                  <div>
                    <img src="/assets/Ellipse 1.png" alt="" />
                  </div>
                  <div>
                    <h3>{t('AboutUsName2')}</h3>
                    <h4>{t('AboutUsPosition2')}</h4>

                  </div>
                </div>
                <p>“Diam eu aliquam eget pretium metus. Vulputate arcu nibh morbi suspendisse. Ut tortor suspendisse cras eget condimentum sociis ut quis dui. Turpis maecenas neque, varius mattis feugiat.” </p>
              </div>
              <div className={style.custmer_list1}>
                <img className={style.quotes_image} src="/assets/Group 599.svg" alt="" />
                <div className={style.custmer_img_box}>
                  <div>
                    <img src="/assets/Ellipse 1.png" alt="" />
                  </div>
                  <div>
                    <h3>{t('AboutUsName3')}</h3>
                    <h4>{t('AboutUsPosition3')}</h4>

                  </div>
                </div>
                <p>“Diam eu aliquam eget pretium metus. Vulputate arcu nibh morbi suspendisse. Ut tortor suspendisse cras eget condimentum sociis ut quis dui. Turpis maecenas neque, varius mattis feugiat.” </p>
              </div>
            </div>
            {/* <Pagination isViewAllBtn={false} /> */}
            <CustomPagination pagination={5}  />
          </section>
          <section className={style.car_campany_section_wrapper}>

            <p> {t('AboutUsoffer')}</p>
            {/* <h1> {t('AboutUsClient')}</h1> */}
            <h1>{t(aboutUs?.section5?.title1)}</h1>


            <div className={style.car_logo_section}>
              <div>
                {/* <img src="/assets/lexus.png" alt="" /> */}
             
              <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image1}`} alt=" brand1" />

              </div>
              <div>
                {/* <img src="/assets/nisan.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image2}`} alt=" brand2" />

              </div>
              <div>
                {/* <img src="/assets/mitach.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image3}`} alt=" brand3" />

              </div>
              <div>
                {/* <img src="/assets/hundai.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image4}`} alt=" brand4" />

              </div>
              <div>
                {/* <img src="/assets/bmw.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image5}`} alt=" brand5" />

              </div>

              <div>
                {/* <img src="/assets/audi.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image6}`} alt=" brand6" />

              </div>
              <div>
                {/* <img src="/assets/ww.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image7}`} alt=" brand7" />

              </div>
              <div>
                {/* <img src="/assets/toyata.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image8}`} alt=" brand8" />

              </div>

              <div>
                {/* <img src="/assets/kia.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image9}`} alt=" brand9" />

              </div>
              <div>
                {/* <img src="/assets/renult.png" alt="" /> */}
                <img src={`${IMG_URL}/aboutus/${aboutUs?.section5?.image10}`} alt=" brand10" />

              </div>
            </div>

          </section>


          <section className={style.hero_section_wrapper}>

            <div className={style.hero_image_div} style={{
              backgroundImage: `url(${BottomHeroImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '300px',

            }}>

              <div className={style.hero_content_section}>
                <h1>{t('AboutUsGetTouch')}</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>

                <div className={style.contact_icon_section}>
                  <section >
                    <div className={style.sivg_icon}>
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_2363)">
                          <path d="M19.0999 14.978V18.1604C19.1 18.3883 19.0137 18.6077 18.8583 18.7744C18.703 18.9411 18.4902 19.0427 18.2629 19.0586C17.8696 19.0856 17.5483 19.1 17.2999 19.1C9.3466 19.1 2.8999 12.6533 2.8999 4.70002C2.8999 4.45162 2.9134 4.13032 2.9413 3.73702C2.95725 3.50972 3.05883 3.29693 3.22553 3.14159C3.39223 2.98625 3.61164 2.89992 3.8395 2.90002H7.0219C7.13354 2.89991 7.24123 2.9413 7.32406 3.01614C7.40689 3.09099 7.45894 3.19395 7.4701 3.30502C7.4908 3.51202 7.5097 3.67672 7.5277 3.80182C7.70656 5.05006 8.0731 6.26407 8.6149 7.40272C8.7004 7.58272 8.6446 7.79782 8.4826 7.91302L6.5404 9.30082C7.72791 12.0678 9.93299 14.2729 12.7 15.4604L14.086 13.5218C14.1427 13.4426 14.2253 13.3858 14.3195 13.3613C14.4138 13.3368 14.5136 13.3462 14.6017 13.3877C15.7402 13.9285 16.9539 14.2941 18.2017 14.4722C18.3268 14.4902 18.4915 14.51 18.6967 14.5298C18.8076 14.5412 18.9104 14.5933 18.985 14.6761C19.0597 14.759 19.1009 14.8665 19.1008 14.978H19.0999Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_2363">
                            <rect width="21.6" height="21.6" fill="white" transform="translate(0.199707 0.200073)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>

                    <div className={style.contact_number}>
                      <p>{t('AboutUsCall')}</p>
                      <span>{t('AboutUsCallNumber')}</span>
                    </div>

                  </section>
                  <section>
                    <div className={style.sivg_icon}>
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_2369)">
                          <path d="M2.89951 2.90002H19.0995C19.3382 2.90002 19.5671 2.99485 19.7359 3.16363C19.9047 3.33241 19.9995 3.56133 19.9995 3.80002V18.2C19.9995 18.4387 19.9047 18.6676 19.7359 18.8364C19.5671 19.0052 19.3382 19.1 19.0995 19.1H2.89951C2.66082 19.1 2.4319 19.0052 2.26312 18.8364C2.09433 18.6676 1.99951 18.4387 1.99951 18.2V3.80002C1.99951 3.56133 2.09433 3.33241 2.26312 3.16363C2.4319 2.99485 2.66082 2.90002 2.89951 2.90002ZM11.0535 10.7147L5.28271 5.81422L4.11721 7.18582L11.0652 13.0853L17.8881 7.18132L16.7109 5.81962L11.0544 10.7147H11.0535Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_2369">
                            <rect width="21.6" height="21.6" fill="white" transform="translate(0.199707 0.200073)" />
                          </clipPath>
                        </defs>
                      </svg>


                    </div>

                    <div className={style.contact_number}>
                      <p>{t('AboutUsMail')}</p>
                      <span>{t('AboutUsMailName')}</span>
                    </div>

                  </section>
                  <section>
                    <div className={style.sivg_icon}>
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_2375)">
                          <path d="M16.7275 15.8277L10.9999 21.5553L5.27231 15.8277C4.13951 14.6948 3.36807 13.2516 3.05554 11.6803C2.74301 10.1091 2.90342 8.48044 3.51649 7.00036C4.12956 5.52029 5.16776 4.25525 6.4998 3.36521C7.83183 2.47518 9.39788 2.00012 10.9999 2.00012C12.6019 2.00012 14.168 2.47518 15.5 3.36521C16.832 4.25525 17.8702 5.52029 18.4833 7.00036C19.0964 8.48044 19.2568 10.1091 18.9443 11.6803C18.6317 13.2516 17.8603 14.6948 16.7275 15.8277V15.8277ZM10.9999 13.7001C11.9547 13.7001 12.8704 13.3208 13.5455 12.6456C14.2206 11.9705 14.5999 11.0548 14.5999 10.1001C14.5999 9.14527 14.2206 8.2296 13.5455 7.55447C12.8704 6.87934 11.9547 6.50005 10.9999 6.50005C10.0451 6.50005 9.12945 6.87934 8.45432 7.55447C7.77919 8.2296 7.39991 9.14527 7.39991 10.1001C7.39991 11.0548 7.77919 11.9705 8.45432 12.6456C9.12945 13.3208 10.0451 13.7001 10.9999 13.7001V13.7001ZM10.9999 11.9001C10.5225 11.9001 10.0647 11.7104 9.72711 11.3728C9.38955 11.0353 9.1999 10.5774 9.1999 10.1001C9.1999 9.62266 9.38955 9.16483 9.72711 8.82726C10.0647 8.48969 10.5225 8.30005 10.9999 8.30005C11.4773 8.30005 11.9351 8.48969 12.2727 8.82726C12.6103 9.16483 12.7999 9.62266 12.7999 10.1001C12.7999 10.5774 12.6103 11.0353 12.2727 11.3728C11.9351 11.7104 11.4773 11.9001 10.9999 11.9001Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_2375">
                            <rect width="21.6" height="21.6" fill="white" transform="translate(0.199707 0.200073)" />
                          </clipPath>
                        </defs>
                      </svg>


                    </div>

                    <div className={style.contact_number}>
                      <p>{t('AboutUsHere')}</p>
                      <span>{t('AboutUsName')} </span>
                    </div>

                  </section>
                </div>
              </div>

            </div>

          </section>
        </div>
      )}

    </>
  )
}
