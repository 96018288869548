import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { API_BASE_URL } from '../../config/config';
import style from './payment.module.css';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';



const PaymentForm = () => {
  const { id } = useParams();
 const fetchConfig = getFetchConfig();
 

  const [orderAvailable, setOrderAvailable] = useState(null);
  const [amount, setAmount] = useState('100.50');
  const [currencyCode, setCurrencyCode] = useState('SAR');
  const [sessionId, setSessionId] = useState('unique-session-id');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
 
  const [userAllProfile, setUserAllProfile] = useState([])
    const [userProfileInfo, setUserProfileInfo] = useState(``);

    const [userDetails, setUserDetails] = useState(null);
      

    const fetchUserAllProfile = () => {
  
      fetch(`${API_BASE_URL}/profile`, {
        ...fetchConfig,
        method: `GET`,
      })
        .then(handleFetchErrors)
        .then((data) => {
          
          setUserAllProfile(data.data.user);
          setUserProfileInfo(data.data.user.userInfo);
          // console.log('userinfoooooooooooooooooooooo,', dataUserAllProfile.data.user.createdAt)
        })
        .catch(standardFetchHandlers.error);
    };
    useEffect(() => {
      fetchUserAllProfile();
    }, []);
    

  // Fetch order details on component mount
  useEffect(() => {
    if (id) {
      fetch(`${API_BASE_URL}/productOrder/oderdetail/${id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setOrderAvailable(data.data.orders);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error.message);
          setError("Failed to fetch order details");
          setIsLoading(false);
        });
    }
  }, [id]);

  // Trigger payment once order details are fetched
  useEffect(() => {
    const initiatePayment = async () => {
      if (!orderAvailable) return;

      setError(null); // Clear any previous errors
      

      try {
        // Prepare the data for the payment request
        const paymentRequestData = {
          order_id:orderAvailable._id,
          amount:orderAvailable.total,
          currency_code: currencyCode,
          session_id: sessionId,
         // user:userAllProfile,
          
        };
     
        const response = await fetch(`${API_BASE_URL}/payments/create-payment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(paymentRequestData),
        });

        // Check if the response is ok (status 200-299)
        // if (!response.ok) {
        //   throw new Error('Failed to create payment. Server responded with error.');
        // }

        // Parse the response body as JSON
        const data = await response.json();

        // Check if the response includes the checkout_url
        if (data && data.checkout_url) {
          // Redirect the user to the checkout URL
          window.location.href = data.checkout_url;
        } else {
          throw new Error('Invalid response from the server. No checkout URL found.');
        }
      } catch (error) {
        // console.error('Payment error:', error);
        // setError('Failed to create payment. Please try again.');
      }
    };

    // Trigger the payment process if order details are available
    if (orderAvailable) {
      initiatePayment();
    }
  }, [orderAvailable, amount, currencyCode, sessionId]);

  return (
    <div className={style.paymentContainer}>
      {/* Display loading spinner while fetching data */}
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ThreeDots 
            height="60" 
            width="100" 
            radius="9"
            color="#4fa94d" 
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        <div>
          {/* Show error message if any */}
          {error && <p className={style.error}>{error}</p>}
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
