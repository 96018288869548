import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import HeroImage from '../../components/hero-image/HeroImage';
import style from './productDetails.module.css';
import { json, Link } from 'react-router-dom';
import { toast } from 'react-toastify';


import ProductImg from '../../assets/images/product-detail-page/Group 575.png';
import NewsDetailHero from '../../assets/images/NewsDetailHero.png';
import Logo_image from '../../assets/images/product-detail-page/Frame 430.png';
import oil_filter_pro from '../../assets/images/oil_filter_pro.png';
import airfilter from '../../assets/images/airfilter.png';
import hydra1 from '../../assets/images/hydra1.png';
import braks1 from '../../assets/images/braks1.png';
import cabin_pro from '../../assets/images/cabin_pro.png';
import control_pro from '../../assets/images/control_pro.png';
import ignition from '../../assets/images/ignition.png';
import ball from '../../assets/images/ball.png';
import coll from '../../assets/images/coll.png';
import eva_pro from '../../assets/images/eva_pro.png';
import car_pro from '../../assets/images/car_pro.png';
import batt from '../../assets/images/batt.png';
import red_product from '../../assets/images/red_product.png';
import hydro from '../../assets/images/hydro.png';
import BottomHeroImage from '../../assets/images/CustomerReviewsbottomHero.png'
import BottomHero from '../../components/bottom-hero/BottomHero'
import wiper_pro from '../../assets/images/wiper_pro.png';
import ProductCard from '../../components/product-card/ProductCard';
import SubCategoriesCard from '../../components/product/subcategories-cards/SubCategoriesCard';
import { useTranslation } from "react-i18next";

import { useSearchParams, useNavigate } from 'react-router-dom';

import CommonModalWrapper from '../../components/common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../../components/Icons/CloseIcon';
import SearchProduct from '../../components/search-product/SearchProduct';

import RegisterCard from '../../components/auth/register-card/RegisterCard';
import LoginCard from '../../components/auth/login-card/LoginCard';
import ForgotPasswordCard from '../../components/auth/forgot-password-card/ForgotPasswordCard';

import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import Loader from '../../components/loader/Loader';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { API_BASE_URL, IMG_URL } from '../../config/config';
import { updateCartCount } from '../../utils/commonFunctions';
import CustomSwiper from '../../components/swiperComponent/CustomSwiper';

export default function ProductDetails({ setcartCount, cartWishCount, setCartWishCount, cartCount }) {
    const { t } = useTranslation();

    // const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
    // // const appURL = "http://localhost:3003/api/v1"
    // const imageURL = `https://admin-anycarpart.go-demo.com`;
    const imageURL = `http://localhost:3003/`;
    const imageURLs = `https://admin-anycarpart.go-demo.com/Upload/subcategory_image`
    const fetchConfig = getFetchConfig();
    const tokenExists = window.localStorage.getItem("token")

    const [loader, setLoader] = useState(true);


    let [num, setNum] = useState(1);

    const [searchParams, setSearchParams] = useSearchParams();
    const productId = searchParams.get('productId');
    const subcategoryId = searchParams.get('subcategoryId');
    
    const [productDetails, setProductDetails] = useState([]);
    const [updateProductId, setUpdateProductId] = useState(false);
    const [productImg, setProductImg] = useState([]);
    const [productRelatedDetails, setProductRelatedDetails] = useState([]);
    const [productCustomersView, setProductCustomersView] = useState([]);
    const [productPromotionalDetails, setProductPromotionalDetails] = useState([]);
    const [productLastView, setProductLastView] = useState([]);
    const [updateCartProduct, setupdateCartProduct] = useState([]);

    const [productCategoriesId, setproductCategoriesId] = useState(``);
    const [productSubCategories, setProductSubCategories] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [allCartProduct, setAllCartProduct] = useState([]);
    const [isFavorite,setIsFavorite] = useState(false)
    
    const Cart = window.localStorage.getItem('ProductCart')

    const CartProduct = Cart ? JSON.parse(Cart) : [];

   //  const [cartWishCount, setcartWishCount] = useState(false);

    const [modalIsOpen, updateModalVisibility] = useState(false);
    const [modalIsOpenForLogin, updateModalVisibilityLogin] = useState(false);
    const [modalIsOpenForForgotPassWord, updateModalVisibilityForForgotPassWord] = useState(false);
    const [modalIsOpenForSearch, updateModalVisibilityForSearch] = useState(false);
    const [vichledata, setvichledata] = useState()
    const [thumbnail, setthumbnail] = useState()
    const [isActive, setisActive] = useState({ id: "", isActive: false })
    const [isheart, setisheart] = useState(false)


    const navigate = useNavigate();
    const goToProduct = () =>
        navigate({
            pathname: '/product',
            search: `?categoryId=${productCategoriesId}&subCategoryId[0]=${subcategoryId}`,
        });
    const goToHome = () =>
        navigate('/');

    const toggleModalForRegisterForSearch = () => {
        updateModalVisibilityForSearch(true)
    }


    const toggleModalForRegister = (toggleValue = false) => {
        updateModalVisibilityLogin(false)
        updateModalVisibility(toggleValue)
    }

    const toggleModalForLogin = (toggleValue = false) => {
        updateModalVisibility(false)
        updateModalVisibilityLogin(toggleValue)
    }

    const toggleModalForForgotPassWord = (toggleValue = false) => {
        updateModalVisibilityLogin(false)
        updateModalVisibilityForForgotPassWord(toggleValue)
    }

    const [isOpen, setIsOpen] = useState(false);

    const [tokenExist, updateToken] = useState(false);

    const openPopModel = () => {
        setIsOpen(true);
    }

    
    
    const fetchPrductDetails = () => {

        fetch(`${API_BASE_URL}/product/productDetails`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                productId: productId
            })
        })
            .then(handleFetchErrors)
            .then((dataProduct) => {
                setproductCategoriesId(dataProduct?.data?.category);
                setProductDetails(dataProduct.data.product);
                setIsFavorite(dataProduct.data.product.isFavorite);
                setProductImg(dataProduct.data.product.image);
                setthumbnail(dataProduct.data.product.thumbnail && dataProduct.data.product.thumbnail[0])
            })
            .catch(standardFetchHandlers.error);

    };
    const fetchCategoryIdPrductDetails = () => {

        fetch(`${API_BASE_URL}/product/productDetails`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                productId: productId
            })
        })
            .then(handleFetchErrors)
            .then((dataProduct) => {

                setproductCategoriesId(dataProduct?.data?.category);
            })
            .catch(standardFetchHandlers.error);

    };
    useEffect(() => {

        fetchCategoryIdPrductDetails();
        fetchPrductSubCategories();
        fetchProductCategories();

    }, []);
    const fetchRelatedProduct = () => {
        fetch(`${API_BASE_URL}/product/get-related-products?subcategoryId=${subcategoryId}`, {
            ...fetchConfig,
            method: 'GET',
        })
            .then(handleFetchErrors)
            .then((dataRelatedProduct) => {
              
                setProductRelatedDetails(dataRelatedProduct.data.products); // Assuming the response object contains a key named 'products'
            })
            .catch(standardFetchHandlers.error);
    };


    const fetchPromotionalProduct = () => {
        fetch(`${API_BASE_URL}/product`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                subcategoryId: [subcategoryId],
                feature: 1
            })
        })
            .then(handleFetchErrors)
            .then((dataPromotionalProduct) => {

             
                setProductPromotionalDetails(dataPromotionalProduct.data.product);
            })
            .catch(standardFetchHandlers.error);

    };
    const fetchCustomersViewPrduct = () => {

        fetch(`${API_BASE_URL}/product/customerViewProduct`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                subcategoryId: subcategoryId
            })
        })
            .then(handleFetchErrors)
            .then((dataCustomersViewProduct) => {
                
                console.log('dataCustomersViewProduct',dataCustomersViewProduct)
                setProductCustomersView(dataCustomersViewProduct.data.product);
            })
            .catch(standardFetchHandlers.error);

    };
    const fetchCustomersLastView = () => {

        fetch(`${API_BASE_URL}/product/lastViewProduct`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                skip: 0,
                limit: 20
            })
        })
            .then(handleFetchErrors)
            .then((dataLastViewProduct) => {
                // console.log('---x----x----x----x----')
                // console.log(dataLastViewProduct)
                setProductLastView(dataLastViewProduct.data.product);
            })
            .catch(standardFetchHandlers.error);

    };
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
      
    }, [quantity]); // Run useEffect whenever quantity changes

    const incNum = () => {
        setQuantity(prevQuantity => prevQuantity + 1); // Update quantity by incrementing
    };

    const decNum = () => {
        if (quantity > 1) { // Ensure quantity doesn't go below 1
            setQuantity(prevQuantity => prevQuantity - 1); // Update quantity by decrementing
        }
    };
    const handleChange = (e) => {
        setNum(e.target.value);
    }
    const updateCartPrduct = (num) => {

        fetch(`${API_BASE_URL}/cart/updateCart`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                id: productId,
                quantity: num
            })
        })
            .then(handleFetchErrors)
            .then((dataupdateCartPrduct) => {
                // console.log('---x----x----x----x----')
              
                navigate('/shopping-cart')

            })
            .catch(standardFetchHandlers.error);

    };
    const updatefavoritePrduct = (productById) => {

        fetch(`${API_BASE_URL}/product/favoriteProduct`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                productId: productById
            })
        })
            .then(handleFetchErrors)
            .then((datafavoritePrduct) => {
                // console.log('---x----x----x----x----')
                // console.log(dataupdateCartPrduct.data);
                fetchPrductDetails();
            })
            .catch(standardFetchHandlers.error);

    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const fetchPrductSubCategories = () => {
        //console.log('productCategoriesId', productCategoriesId);
        if (productCategoriesId) {
            fetch(`${API_BASE_URL}/category/subcategory/${productCategoriesId}`, {
                ...fetchConfig,
                method: `GET`
            })
                .then(handleFetchErrors)
                .then((dataproductSubCategories) => {
                    const productCategoriesArr = dataproductSubCategories?.data?.subCategories;
                    setProductSubCategories(productCategoriesArr)

                })
                .catch(standardFetchHandlers.error);

        }

    }

    function getLastDigitMonth(inputNumber) {
        const lastDigit = inputNumber % 10; // Get the last digit

        switch (lastDigit) {
            case 1:
                return "January";
            case 2:
                return "February";
            case 3:
                return "March";
            case 4:
                return "April";
            case 5:
                return "May";
            case 6:
                return "June";
            case 7:
                return "July";
            case 8:
                return "August";
            case 9:
                return "September";
            case 0:
                return "October";
            default:
                return "Invalid month";
        }
    }

    const fetchProductCategories = () => {


        fetch(`${API_BASE_URL}/category`, {
            ...fetchConfig,
            method: `GET`
        })
            .then(handleFetchErrors)
            .then((productCategories) => {
                const productCategoriesArr = productCategories?.data?.category;

                setProductCategories(productCategoriesArr);

            })
            .catch(standardFetchHandlers.error);
    };
    useEffect(() => {

        fetchPrductDetails();
        fetchPrductSubCategories();
        fetchProductCategories();
    }, []);
    useEffect(() => {

        fetchRelatedProduct();
        fetchPromotionalProduct();
        fetchCustomersViewPrduct();
        fetchCustomersLastView();

    }, []);
    useEffect(() => {

        fetchPrductDetails();

    }, [productId, updateProductId]);
    useEffect(() => {

        fetchRelatedProduct();

    }, [productId, updateProductId]);
    useEffect(() => {
        fetchRelatedProduct();
        fetchPrductDetails();

    }, [true, cartWishCount]);
    useEffect(() => {

        fetchPromotionalProduct();

    }, [productId, updateProductId]);
    useEffect(() => {

        fetchCustomersViewPrduct();

    }, [productId, updateProductId]);
    useEffect(() => {

        fetchCustomersLastView();

    }, [productId, updateProductId]);


    useEffect(() => {

        if (localStorage.getItem(`token`)) {
            updateToken(true)
        }
    }, [localStorage.getItem(`token`), tokenExist])
    const [value, setValue] = React.useState(0);

    const handleChanged = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {

        if (productDetails) {
            
            if (productDetails.priautoNumber) {
                fetch(`${API_BASE_URL}/product/fetchvichle_data/${productDetails.priautoNumber}`, {
                    ...fetchConfig,
                    method: `GET`
                })
                    .then(handleFetchErrors)
                    .then((dataproductSubCategories) => {
                        
                        setvichledata(dataproductSubCategories.data.vehicle)
                       
                    })
                    .catch(standardFetchHandlers.error);
            }

        }
    }, [])

    const imageHandler = (image, data1) => {
        setthumbnail(image)
        setisActive({ id: data1, isActive: true })


    }

    const addtoWishListHandler = () => {
        if (tokenExists) {
            fetch(`${API_BASE_URL}/product/favoriteProduct`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    productId: productDetails._id
                })
            })
            .then(handleFetchErrors)
            .then((dataupdateCartPrduct) => {
               
                if (dataupdateCartPrduct.data.favproduct && dataupdateCartPrduct.data.favproduct.deletedCount) {
                    setIsFavorite(false)
                    toast.success("Product removed from wishlist.");
                    updateCartCount(setcartCount, setCartWishCount);
                } else {
                    setIsFavorite(true)
                    // local storage to check if the product is already in the wishlist, product id wise true

                    toast.success("Product added to wishlist.")
                    updateCartCount(setcartCount, setCartWishCount);
                }
            })
            .catch((error) => {
                console.log(error.message)
            });
        } else {
            // User is not logged in, show login modal
            toggleModalForLogin(true);
            return; // Exit function early
        }
    
        // Proceed with existing functionality for logged-in user
        const data = window.localStorage.getItem("Product");
        if (!data) {
            let product = [productDetails]; // Assuming productDetails is a variable containing product data.
            const newProduct = JSON.stringify(product);
            window.localStorage.setItem("Product", newProduct);
            //toast.success("Product added to wishlist.");
        } else {
            const existingProducts = JSON.parse(data);
            const isProductInWishlist = existingProducts.filter((item) => item._id === productDetails._id)
            if (isProductInWishlist.length === 0) {
                existingProducts.push(productDetails);
                const newProduct = JSON.stringify(existingProducts);
                window.localStorage.setItem("Product", newProduct);
                //toast.success("Product added to wishlist.");
            } else {
               // toast.success("Product is already in your wishlist.");
            }
        }
    };
    
    const addTocartListHandler = () => {
        if (tokenExists) {
            fetch(`${API_BASE_URL}/cart/updateCart`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    id: productDetails._id,
                    quantity: quantity // Use updated quantity state here
                })
            })
            .then(handleFetchErrors)
            .then((dataupdateCartPrduct) => {
                //toast.success("Product added to the cart");
                updateCartCount(setcartCount, setCartWishCount);
            })
            .catch((error) => {
                console.log(error.message);
            });
        } else {
            // User is not logged in, show login modal
            toggleModalForLogin(true);
            return; // Exit function early
        }
    
        // Proceed with existing functionality for logged-in user
        let cart = window.localStorage.getItem("ProductCart");
        if (!cart) {
            const updatedProductDetails = { ...productDetails, quantity: quantity }; // Update quantity in productDetails
            updatedProductDetails.totalPrice = updatedProductDetails.price * quantity;
            const newProduct = JSON.stringify([updatedProductDetails]);
            window.localStorage.setItem("ProductCart", newProduct);
             toast.success("Product added to Cart.");
        } else {
            const existingProducts = JSON.parse(cart);
            let updated = false;
    
            for (let i = 0; i < existingProducts.length; i++) {
                if (existingProducts[i]._id === productDetails._id) {
                    existingProducts[i].quantity = quantity; // Update quantity
                    existingProducts[i].totalPrice = existingProducts[i].price * quantity; // Update total price
                    updated = true;
                    break;
                }
            }
    
            if (!updated) {
                const updatedProductDetails = { ...productDetails, quantity: quantity }; // Update quantity in productDetails
                updatedProductDetails.totalPrice = updatedProductDetails.price * quantity;
                existingProducts.push(updatedProductDetails);
            }
    
            const newProduct = JSON.stringify(existingProducts);
            window.localStorage.setItem("ProductCart", newProduct);
            toast.success("Product added to Cart or quantity updated in Cart.");
            updateCartCount(setcartCount, setCartWishCount);
        }
    };
    



    return (
        <>
            <CommonModalWrapper isOpen={modalIsOpen} >
                <CloseIcon onClick={() => toggleModalForRegister(false)} />

                <RegisterCard toggleModalForLogin={toggleModalForLogin} updateModalVisibility={updateModalVisibility} />
            </CommonModalWrapper>
            <CommonModalWrapper isOpen={modalIsOpenForLogin} className={style.login_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForLogin(false)} />
                <LoginCard toggleModalForForgotPassWord={toggleModalForForgotPassWord} toggleModalForRegister={toggleModalForRegister} updateModalVisibilityLogin={updateModalVisibilityLogin} />
            </CommonModalWrapper>
            {/* forgetPassword */}
            <CommonModalWrapper isOpen={modalIsOpenForForgotPassWord} className={style.login_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForForgotPassWord(false)} />
                <ForgotPasswordCard />
            </CommonModalWrapper>
            <CommonModalWrapper isOpen={modalIsOpenForSearch} className={style.search_modal_opener} >
                <CloseIcon onClick={() => toggleModalForRegisterForSearch(false)} />
                <SearchProduct />
            </CommonModalWrapper>
            <HeroImage HeroImage={NewsDetailHero} Heading={productDetails.title} />
            <div className={style.product_details_sec} >
                <div className={style.product_details_img}>
                    {/* <img src={ProductImg} alt="" /> */}
                    <img src={thumbnail ? `${IMG_URL}/${thumbnail}` : "../assets/images/noimg-png-output.png"} alt="" />
                    <div className={style.product_details_imges_list}>
                        {productImg && productImg.length > 0 &&
                            productImg.map((allProductImg, i) => {
                                if (i < 4) {
                                    return (
                                        <div style={{ border: isActive.id === allProductImg ? '2px solid orange' : '2px solid #f5f5f5' }}>
                                            {/* {console.log("imageURLs}/${allProductImg", `${IMG_URL}/${allProductImg}`)} */}
                                            <img
                                                src={`${IMG_URL}/${allProductImg}`}
                                                onClick={() => {
                                                    imageHandler(allProductImg);
                                                }}
                                                alt=""
                                                onError={(e) => {
                                                    e.target.src = "../assets/images/noimg-png-output.png";
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            })
                        }

                    </div>
                    {/* <img src={`${imageURL}/${productDetails.thumbnail}`} alt="" /> */}
                </div>
                <div className={style.product_details_right_bar}>
                    <ul>
                        <li style={{ cursor: 'pointer' }} onClick={goToHome}>
                            {t('ProductHome')} <i className="fa-solid fa-angle-right"></i>
                        </li>
                        {
                            productCategories && productCategories.length ?
                                productCategories.map((allCategory) => {
                                    if (allCategory._id == productCategoriesId) {
                                        return (
                                            <li style={{ cursor: 'pointer' }} onClick={goToProduct}>
                                                {allCategory.title}<i className="fa-solid fa-angle-right"></i>
                                            </li>
                                        )
                                    }
                                })
                                : null

                        }
                        {
                            productSubCategories && productSubCategories.length ?
                                productSubCategories.map((allSubCategory) => {
                                    if (allSubCategory._id == subcategoryId) {
                                        return (
                                            <li style={{ cursor: 'pointer' }} onClick={goToProduct}>
                                                {allSubCategory.title}<i className="fa-solid fa-angle-right"></i>
                                            </li>
                                        )
                                    }
                                })
                                : <li style={{ cursor: 'pointer' }} onClick={goToProduct}>
                                    {t('HeaderCategories')}<i className="fa-solid fa-angle-right"></i>
                                </li>
                        }

                    </ul>
                    <h1>{productDetails.title}</h1>
                    <p>
                        {productDetails.short_description}
                    </p>
                    { productDetails?.old_price && productDetails?.price != productDetails?.old_price ? (
                    <div>
                        <h3 style={{ textDecoration: 'line-through', color: 'red' }}>
                        {Number(productDetails?.old_price).toFixed(2)} SAR
                        </h3>
                        <h3>
                        {Number(productDetails?.price).toFixed(2)} <span style={{marginLeft: '1px'}}>SAR</span>
                        </h3>
                       
                    </div>
                    ) : (
                        <h3>
                        {(productDetails?.price && !isNaN(productDetails?.price) 
                          ? Number(productDetails?.price).toFixed(2) 
                          : '0.00' )} 
                        <span style={{marginLeft: '1px'}}>SAR</span>
                      </h3>
                    )}

                   
                    <div>
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                <h4 style={{ position: "relative" }}>
                                    {t('Productsku')}:
                                </h4>
                                <span style={{ marginLeft: "8px", display: "inline-block", fontSize: 18 }}>
                                    {productDetails.sku}
                                </span>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                <h4 style={{ position: "relative" }}>
                                    {t('Productmpn')}:
                                </h4>
                                <span style={{ position: "relative", top: "-6px", marginLeft: "8px", display: "inline-block", fontSize: 18 }}>
                                    {productDetails.part_number || 0}
                                </span>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                <h4 style={{ position: "relative" }}>
                                    {t('Productwight')}:
                                </h4>
                                <span style={{ position: "relative", top: "-6px", marginLeft: "8px", display: "inline-block", fontSize: 18 }}>
                                    {productDetails.weight || 0}
                                </span>

                            </div>
                            {productDetails.oe_number && (
                                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                    <h4 style={{ position: "relative" }}>
                                        OE Number:
                                    </h4>
                                    <span style={{ position: "relative", top: "-6px", marginLeft: "8px", display: "inline-block", fontSize: 18 }}>
                                        {productDetails.oe_number}
                                    </span>
                                </div>
                            )}
                            {productDetails.priautoNumber && (
                                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>

                                    <h4 style={{ position: "relative" }}>
                                        PRIAUTO Number:
                                    </h4>
                                    <span style={{ display: "inline-block", fontSize: 18 }}>
                                        {productDetails.priautoNumber}
                                    </span>
                                </div>
                            )}
                            {productDetails.returnable && (
                                <div style={{ marginTop: 15, display: "flex", justifyContent: 'space-between', alignItems: "center" }}>

                                    <h4 style={{ position: "relative" }}>
                                        Returnable:
                                    </h4>
                                    <span style={{
                                        fontSize: 16,
                                        color: productDetails.returnable === "Yes" ? "#357a38" : "red"
                                    }}>
                                        {productDetails.returnable === "Yes" ?
                                            `It is returnable within ${productDetails.returnableDays} days` :
                                            "Not returnable once purchased."
                                        }
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>


                    <div className={style.Button_box}>
                        <>
                            <div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '0.5px solid #ccc',  /* lighter border color */
                                    width: '150px',  /* total width */
                                }}>
                                    <span onClick={decNum} className={quantity === 1 ? 'disabled' : ''} style={{
                                        cursor: 'pointer',
                                        marginLeft: '5px',
                                        fontWeight: 'normal',

                                    }}>-</span>
                                    <span style={{
                                        flex: '1',
                                        textAlign: 'center',
                                        fontWeight: 'normal',
                                        fontFamily: 'Arial, sans-serif'

                                    }}>{quantity}</span>
                                    <span onClick={incNum} style={{
                                        cursor: 'pointer',
                                        marginRight: '5px',
                                        fontWeight: 'normal',

                                    }}>+</span>
                                </div>





                            </div>

                            {productDetails.stock > 0 ? ( // out of stock must be show
                                <button className={style.add_cart_unlogin} onClick={() => { addTocartListHandler() }} >{t('ProductCartbtn')}</button>
                            ) : (
                                <button className={style.add_cart_unlogin} disabled>{t('ProductCartbtnOutofStock')}</button>  
                            )}
                            
                            {tokenExist ?  isFavorite ? (
                                <Button variant="outlined" className={style.Add_list_btn } onClick={() => { addtoWishListHandler() }} >{t('ProductRemoveWishBtn')}</Button>
                            ) : (
                                <Button variant="outlined" className={style.Add_list_btn} onClick={() => { addtoWishListHandler() }} >{t('ProductWishBtn')}</Button>
                            ) :  <Button variant="outlined" className={style.Add_list_btn} onClick={() => { addtoWishListHandler() }} >{t('ProductWishBtn')}</Button>
                        } 
                        </>

                        {/* <button className={style.add_cart} onClick={() => { updateCartPrduct(num) }}>{t('ProductCartbtn')}</button> */}
                    </div>
                    <div className={style.logo_img}>
                        <img src="/assets/image 13.svg" alt="" />
                        <img src="/assets/image 14.svg" alt="" />
                        <img src="/assets/image 15.svg" alt="" />
                        <img src="/assets/image 16.svg" alt="" />
                    </div>
                    {/* <div className={style.tabs_box_lists}>
                        <div className={style.items_list}>
                            <ul>
                                <li >
                                    <a className={style.active_li_tab} href="#">{t('Productdescription')}</a>
                                </li>
                                <li >
                                    <a href="#">{t('Productshiping')}</a>
                                </li>
                                <li>
                                    <a href="#">{t('Productfaq')}</a>
                                </li>
                            </ul>
                            <p>{productDetails.description}</p>
                        </div>
                    </div> */}
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChanged} aria-label="basic tabs example">
                                <Tab label="Description" {...a11yProps(0)} />
                                <Tab label="Shipping & Returns" {...a11yProps(1)} />
                                <Tab label="FAQ's" {...a11yProps(2)} />
                                {/* {productDetails.priautoNumber ? (<Tab label="Vehicle Information" {...a11yProps(3)} ></Tab>) : null} */}
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <p>{productDetails?.short_description}</p>
                            {/* Description amet vulputate id ipsum. At nulla scelerisque consectetur non at egestas lectus venenatis. Libero dolor neque convallis id sit augue et consectetur. Quis in convallis risus risus. Nibh at eu sollicitudin in. Amet integer purus aenean interdum pulvinar scelerisque. Gravida sapien, accumsan orci turpis. Duis ipsum ultrices vitae nulla. Nibh diam vitae facilisi quis turpis tellus a. Dapibus facilisis amet est, diam et. Facilisis consequat, in quam vestibulum. Morbi nisl, ultricies elit mauris amet diam.

                            Sit pharetra aliquam lacus, etiam faucibus erat tempor nunc nulla. Non aenean malesuada lobortis sit pharetra, mauris, quisque. Proin mauris viverra ultrices. */}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {productDetails?.returnable === 'Yes' ? (
                                <p style={{ fontSize: '16px' }}>
                                    This product is returnable. You have {productDetails?.returnableDays} days to initiate a return after receiving it. You will not be able to return this order after these days.
                                </p>
                            ) : (
                                <p style={{ fontSize: '16px' }}>This product is not returnable.</p>
                            )}

                            {/* Shipping returns blandit sollicitudin pretium cras nisl, blandit duis. Adipiscing vitae amet vulputate id ipsum. At nulla scelerisque consectetur non at egestas lectus venenatis. Libero dolor neque convallis id sit augue et consectetur. Quis in convallis risus risus. Nibh at eu sollicitudin in. Amet integer purus aenean interdum pulvinar scelerisque. Gravida sapien, accumsan orci turpis. Duis ipsum ultrices vitae nulla. Nibh diam vitae facilisi quis turpis tellus a. Dapibus facilisis amet est, diam et. Facilisis consequat, in quam vestibulum. Morbi nisl, ultricies elit mauris amet diam.

                            Sit pharetra aliquam lacus, etiam faucibus erat tempor nunc nulla. Non aenean malesuada lobortis sit pharetra, mauris, quisque. Proin mauris viverra ultrices. */}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            FAq curabitur blandit sollicitudin pretium cras nisl, blandit duis. Adipiscing vitae amet vulputate id ipsum. At nulla scelerisque consectetur non at egestas lectus venenatis. Libero dolor neque convallis id sit augue et consectetur. Quis in convallis risus risus. Nibh at eu sollicitudin in. Amet integer purus aenean interdum pulvinar scelerisque. Gravida sapien, accumsan orci turpis. Duis ipsum ultrices vitae nulla. Nibh diam vitae facilisi quis turpis tellus a. Dapibus facilisis amet est, diam et. Facilisis consequat, in quam vestibulum. Morbi nisl, ultricies elit mauris amet diam.

                            Sit pharetra aliquam lacus, etiam faucibus erat tempor nunc nulla. Non aenean malesuada lobortis sit pharetra, mauris, quisque. Proin mauris viverra ultrices.
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div>
                                {
                                    vichledata ? (
                                        <>

                                            <table>
                                                {/* <tr>
                                                    <td style={{ fontWeight: "bold" }}>key:</td>
                                                    <td>Value</td>
                                            </tr> */}
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>Manufacturers:</td>
                                                    <td>{vichledata ? vichledata.Manufacturers : null}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>Brand:</td>
                                                    <td>{vichledata ? vichledata.Brand : null}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>Series:</td>
                                                    <td>{vichledata ? vichledata.Series : null}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>Models:</td>
                                                    <td>{vichledata ? vichledata.Models : null}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>Start of Production:</td>
                                                    <td>{vichledata ? `${vichledata.SOP_YEAR}-${getLastDigitMonth(vichledata.SOP)}` : null}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>End of Production:</td>
                                                    <td>{vichledata ? `${vichledata.EOP_YEAR}-${getLastDigitMonth(vichledata.EOP)}` : null}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>VehicleType:</td>
                                                    <td>{vichledata ? vichledata.VehicleType : null}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: "bold" }}>FuelType:</td>
                                                    <td>{vichledata ? vichledata.FuelType : null}</td>
                                                </tr>
                                            </table>

                                        </>
                                    ) : (
                                        <Loader />
                                    )
                                }


                                {/* <h4>PRIAUTO_Number:</h4> */}
                            </div>
                        </TabPanel>
                    </Box>
                </div>
            </div>
            
            <CustomSwiper
                productRelatedDetails={productRelatedDetails}
                cartWishCount={cartWishCount}
            />


            {
                productPromotionalDetails?.length ?

                    <section className={style.product_details_wrapper}>
                        <div className={style.heading2}>
                            <h2>{t('ProductPromotionalProducts')}</h2>
                        </div>
                        <div className={style.product_details_boxes_images}>
                            <div className={style.ProductCardsectt}>
                                {
                                    productPromotionalDetails.map((allproductByFilter, index) => {
                                        if (index < 4) {
                                            return (

                                                <ProductCard wishDetail={allproductByFilter.isFavorite} productId={allproductByFilter._id}
                                                    subcategoryId={allproductByFilter.subcategoryId}
                                                    productThumbnailShort={allproductByFilter?.thumbnail ? allproductByFilter?.thumbnail[0] : ""}
                                                    productTitle={allproductByFilter.title}
		

                                                    productSku={allproductByFilter.sku}
                                                    productCurrency={allproductByFilter.currency}
                                                    productPrice={allproductByFilter.price}
                                                    vinNumber={allproductByFilter.vin_number}
                                                    product={allproductByFilter}
                                                />
                                            )
                                        }
                                    })
                                }
                                {/* <ProductCard image={braks1} name={t('ProductPromotionalProducts1')}/>

                                <ProductCard image={cabin_pro} name={t('ProductPromotionalProducts2')} />
                                    <ProductCard image={control_pro} name={t('ProductPromotionalProducts3')}/>

                                <ProductCard image={ignition}name={t('ProductPromotionalProducts4')}/> */}
                            </div>
                        </div>
                    </section>

                    : null
            }


            {
                productCustomersView?.length ?
                    <section className={style.product_details_wrapper}>
                        <div className={style.heading2}>
                            <h2>{t('ProductCustomerView')}</h2>
                        </div>
                        <div className={style.product_details_boxes_images}>
                            <div className={style.ProductCardsectt}>
                                {
                                    productCustomersView.map((allproductByFilter, index) => {
                                        if (index < 4) {
                                            return (

                                                <ProductCard wishDetail={allproductByFilter.isFavorite} productId={allproductByFilter._id}
                                                    subcategoryId={allproductByFilter.subcategoryId}
                                                    productThumbnailShort={allproductByFilter.thumbnail_short} productTitle={allproductByFilter.title}
                                                    productSku={allproductByFilter.sku}
                                                    productCurrency={allproductByFilter.currency}
                                                    productPrice={allproductByFilter.price}
                                                    vinNumber={allproductByFilter.vin_number}
                                                    product={allproductByFilter}
                                                />
                                            )
                                        }
                                    })
                                }
                                {/* <ProductCard image={hydra1} name={t('ProductCustomerView1')}/> */}
                                {/* <ProductCard image={ball} name={t('ProductCustomerView2')}/>
                                <ProductCard image={coll} name={t('ProductCustomerView3')}/>
                                <ProductCard image={eva_pro} name={t('ProductCustomerView4')}/> */}
                            </div>
                        </div>
                    </section>

                    : null
            }

            {
                productLastView?.length ?
                    <section className={style.product_details_wrapper}>
                        <div className={style.heading2}>
                            <h2>{t('ProductLastView')}</h2>
                        </div>
                        <div className={style.product_details_boxes_images}>
                            <div className={style.ProductCardsectt}>
                                {
                                    productLastView.map((allproductByFilter, index) => {
                                        if (index < 4) {
                                            return (

                                                <ProductCard wishDetail={allproductByFilter.isFavorite} productId={allproductByFilter._id}
                                                    subcategoryId={allproductByFilter.subcategoryId}
                                                    productThumbnailShort={allproductByFilter.thumbnail_short} productTitle={allproductByFilter.title}
                                                    productSku={allproductByFilter.sku}
                                                    productCurrency={allproductByFilter.currency}
                                                    productPrice={allproductByFilter.price}
                                                    vinNumber={allproductByFilter.vin_number}
                                                    product={allproductByFilter}
                                                />
                                            )
                                        }
                                    })
                                }
                                {/* <ProductCard image={car_pro} />

                                <ProductCard image={batt} />
                                <ProductCard image={hydra1} />
                                <ProductCard image={wiper_pro} /> */}
                            </div>
                        </div>
                    </section>

                    : null
            }

            <section className={style.bottom_hero_section}>
                <BottomHero HeroImage={BottomHeroImage} />
            </section>

        </>

    )
}
