import style from "./loginCard.module.css";
import SocialAuthBtn from '../social-auth-btn/SocialAuthBtn';
import Input from '../../input/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFetchConfig from '../../../utils/getFetchConfig';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import {API_BASE_URL} from "../../../config/config";

import { isTruthyString, isValidEmailAddress, isValidIndianMobileNumber } from '../../../utils/verification';
	const imageURL = `https://admin-anycarpart.go-demo.com`;

export default function LoginCard({ toggleModalForRegister = () => { }, toggleModalForForgotPassWord = () => { }, updateModalVisibilityLogin = false}) {
    const { t } = useTranslation();
    const Cart = window.localStorage.getItem('ProductCart')
    const CartProduct = Cart ? JSON.parse(Cart) : [];
    const wishList = window.localStorage.getItem('Product')
    const wishListProduct = wishList ? JSON.parse(wishList) : []
    let token =window.localStorage.getItem('token')

    const fetchConfig = getFetchConfig();

    const [showLoader, toggleLoader] = useState(false),
        [formData, setFormData] = useState({
            email_address: ``,
            password: ``,
        });
 
 
    const { 
        email_address,
        password,
    } = formData

    const onChangeHandlerForFormData = (e) => {
        const inputName = e?.target?.name || e?.name,
            inputValue = e?.target?.value || e?.value;

        setFormData((prev) => ({
            ...prev,
            [inputName]: inputValue
        }));
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        toast.dismiss();

        const missingData = [],
            invalidData = [];

        if (!email_address) missingData.push(`email address`);
        else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`email address`);

        if (!password) missingData.push(`password`);
        else if (password && !isTruthyString(password)) invalidData.push(`password`);

        // Show errors if needed
        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);
            return;
        }

        // if (password !== confirm_password) return toast.error('Password don`t match');

        const userDataObj = {
            email_address,
            password,
     
        };   
        toggleLoader(true);

        fetch(`${API_BASE_URL}/account/login`, {
            ...fetchConfig,
            body: JSON.stringify(userDataObj)
        })
            .then(handleFetchErrors)
            .then((res) => {
              
 
                toggleLoader(false);
                // standardFetchHandlers.success(res);

                if (res?.status == 200) {
                    
                    updateModalVisibilityLogin(false)
                    localStorage.setItem(`token`, res?.data?.token);
                    toast.success(res?.data?.message);
                    
                    toggleModalForRegister(false)
                    // window.location.reload();

                } else if (res?.status == 403 || res?.status == 400) {
                    toast.error(res?.data?.message);
                }
                  
                
            })
            .catch((err) => {
                console.log(err)
            });
    };
    const favoriteProduct =()=>{
        fetch(`${API_BASE_URL}/product/favoriteProduct`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                product:wishListProduct
            })
        })
            .then(handleFetchErrors)
            .then((dataupdateCartPrduct) => {
                window.localStorage.removeItem('Product')
                window.location.reload()
            })
        .catch((error)=>{
            console.log(error.message)
        });
    }


    const updateCart =()=>{
        if(CartProduct.length>0){
        fetch(`${API_BASE_URL}/cart/addcart`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                product: CartProduct
            })
        })
            .then(handleFetchErrors)
            .then((dataupdateCartPrduct) => {
               
                window.localStorage.removeItem('ProductCart');
                window.location.reload()
            })
        .catch((error)=>{
            console.log(error.message)
        });
    }
    }


    useEffect(()=>{
       if(token){
       // favoriteProduct()
        updateCart()
       }
    },[token])


    return (
        <div className={style.card_wrapper}>
            <span className={style.heading}>{t('LoginSign')}</span>
            <h2 className={style.heading_description}>{t('LoginWelcome')}</h2>

            <form onSubmit={onSubmitHandler} className={style.login_form}>
                <Input
                    type='text'
                    placeholder={t('LoginPhone')}
                    label={t('LoginPhone')}
                    name='email_address'
                    value={email_address}
                    onChangeHandler={onChangeHandlerForFormData}
                />

                <Input
                    type='password'
                    placeholder={t('LoginPass')}
                    label={t('LoginPass')}
                    name='password'
                    value={password}
                    onChangeHandler={onChangeHandlerForFormData}
                />
          

            <div className={style.forgot_remember_pass_section}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked />} label={t('LoginRember')}/>
                </FormGroup>

                <p onClick={() => { toggleModalForForgotPassWord(true) }} className={style.recover_password_text}>{t('LoginRecover')}</p>
            </div>

            < SocialAuthBtn heading={t('LoginNetwork')} />

            <section className={style.login_sign_btn_section}>
                <input type="submit" className={style.login_button_show} value={t('LoginSignIn')} />
                <p className={style.register_check_button_show} onClick={() => { toggleModalForRegister(true) }} >{t('LoginRegister')}</p>
            </section>
            </form>
        </div>
    )
}
