import React, { useEffect, useState } from 'react';
import style from './Receipt.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Receiptpage() {
    const navigate = useNavigate();
  
    const location = useLocation();
    const orderNo = location.state?.orderNo ; 
    const OrderresponseData = location.state?.responseData;
    const OrderData = location.state?.responseData;
    const OrdersData = location.state?.OrdersData;
    const print = location.state?.print;
    const [printCount, setPrintCount] = useState(0);

   
    useEffect(() => {
        if (print) {
            setPrintCount(printCount + 1);
            if (printCount === 1) {
                const printContents = document.getElementById('print').innerHTML;
                const originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
                // it will print one time then redirect to success page with state value
                // const state = {
                //     orderNo: orderNo,
                //     OrderresponseData: OrderresponseData,
                //     OrderData: OrderData,
                //     OrdersData: OrdersData
                // }
                //navigate('/Successmessage', { state: state });
            }
        }
    }, [print, printCount]);
    return (
        <div id="print" className={style.main_wrapper}>
            <div className={style.bill_receipt_wrap}>
                <div className={style.bill_receipt_wrap_img} >
                <img src="/assets/Logo-design.svg" alt="Logo Image" />
                </div>
                <div className={style.bill_receipt_wrap_text}>
                    <h1>Business Invoice</h1>
                </div>
            </div>                    
            <div className={style.receipt_address}>
                <div className={style.receipt_address_location}>
                    <div className={style.receipt_address_location_items}>
                        <img src="/assets/Frame 530.png" alt="" />
                        <p>Khurais Road, Riyadh,
                            Saudi Arabia.</p>
                    </div>
                    <div className={style.receipt_address_location_items}>
                        <img src="/assets/Group 530.png" alt="" />
                        <p>+966 386-647-3637
                        </p>
                    </div>
                    <div className={style.receipt_address_location_items}>
                        <img src="/assets/Group 530 (1).png" alt="" />
                        <p>contact@gulfmotors.sa
                        </p>
                    </div>
                </div>
                
                <div className={style.receipt_input_fields_wrap}>
    <div className={style.col_3}>
        <input className={style.effect_1} type="text" disabled={true} placeholder="Invoice No." />
        <span className={style.focus_border} style={{ textAlign: 'left', display: 'inline' }}>
            {orderNo ? (
                <div>{OrdersData[0].orderId}</div>
            ) : (
                <div>{OrderresponseData.orderId}</div>
            )}
        </span>
    </div>
    <div className={style.col_3}>
        <input className={style.effect_1} type="text" disabled={true} placeholder="Date" />
        <span className={style.focus_border} style={{ textAlign: 'right', display: 'inline' }}>
            {orderNo ? (
                <div>{new Date(OrdersData[0].updatedAt).toLocaleDateString('en-GB')}</div>
            ) : (
                <div>{new Date(OrderresponseData.orderDetails[0].updatedAt).toLocaleDateString('en-GB')}</div>
            )}
        </span>
    </div>
    <div className={style.col_3}>
        <input className={style.effect_1} type="text" disabled={true} placeholder="Cust. Id " />
        <span className={style.focus_border}>
            {orderNo ? (
                <div>{OrdersData[0].userId}</div>
            ) : (
                <div>{OrderresponseData.orderDetails[0].userId}</div>
            )}
        </span>
    </div>
   
</div>

            </div>
            <div className={style.receipt_shipped_address}>
                <div className={style.shopping_details_boxes_lists}>
                    <div>
                        <h3>Bill to </h3>
                    </div> 
               
                    <div>
                    {orderNo ? (
                       
                    <ul>
                        <li>{OrderData?.firstname} <span>{OrderData?.lastname}</span></li>
                        <li>{OrderData?.shippingAddress?.city?.title}</li>
                        <li>{OrderData?.email_address}</li>
                        <li>{OrderData?.phonenumber}</li>
                        <li>{OrderData?.shippingAddress?.address}</li>
                        <li>
                        {OrderData?.shippingAddress?.country} <span>{OrderData?.shippingAddress?.zipCode}</span>
                        <span>{OrderData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    ) : (
                    <ul>
                        <li>{OrderresponseData?.user?.first_name} <span>{OrderresponseData?.user?.last_name}</span></li>
                        <li>{OrderresponseData?.shippingAddress?.city?.title}</li>
                        <li>{OrderresponseData?.user?.email_address}</li>
                        <li>{OrderresponseData?.shippingAddress?.phonenumber}</li>
                        <li>{OrderresponseData?.shippingAddress?.address}</li>
                        <li>
                        {OrderresponseData?.shippingAddress?.country} <span>{OrderresponseData?.shippingAddress?.zipCode}</span>
                        <span>{OrderresponseData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    )}
               </div>
                </div>
                <div className={style.shopping_details_boxes_lists}>
                    <div>
                        <h3>Ship to </h3>
                    </div>
                    <div>
                    {orderNo ? (
                    <ul>
                        <li>{OrderData?.firstname} <span>{OrderData?.lastname}</span></li>
                        <li>{OrderData?.shippingAddress?.city?.title}</li>
                        <li>{OrderData?.email_address}</li>
                        <li>{OrderData?.phonenumber}</li>
                        <li>{OrderData?.shippingAddress?.address}</li>
                        <li>
                        {OrderData?.shippingAddress?.country} <span>{OrderData?.shippingAddress?.zipCode}</span>
                        <span>{OrderData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    ) : (
                    <ul>
                        <li>{OrderresponseData?.user?.first_name} <span>{OrderresponseData?.user?.last_name}</span></li>
                        <li>{OrderresponseData?.shippingAddress?.city?.title}</li>
                        <li>{OrderresponseData?.user?.email_address}</li>
                        <li>{OrderresponseData?.shippingAddress?.phonenumber}</li>
                        <li>{OrderresponseData?.shippingAddress?.address}</li>
                        <li>
                        {OrderresponseData?.shippingAddress?.country} <span>{OrderresponseData?.shippingAddress?.zipCode}</span>
                        <span>{OrderresponseData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    )}
               </div>
                </div>
            </div>
            <div className={style.receipt_table_box}>
            
                <table>
                    <thead>
                        <tr>
                        <th>Item No.</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderNo ? (
                        OrdersData?.map((item, index) => (
                            <tr key={item.orderId}>
                            <td>{item.productId}</td>
                            <td>
                                {item.productDescription.slice(0, 50)}
                                {item.productDescription.length > 50 ? '...' : ''}
                            </td>
                            <td>{item.quantity}</td>
                            <td>{item.price.toFixed(2)}</td>
                            <td>{((Number(item.price)*Number(item.quantity)).toFixed(2))}</td>
                            </tr>
                        ))
                        ) : (
                        OrderresponseData?.orderDetails?.map((item, index) => (
                            <tr key={item.orderId}>
                            <td>{item.productId}</td>
                            <td>
                                {item.productDescription.slice(0, 50)}
                                {item.productDescription.length > 50 ? '...' : ''}
                            </td>
                            <td>{item.quantity}</td>
                            <td>{item.price.toFixed(2)}</td>
                            <td>{((Number(item.price)*Number(item.quantity)).toFixed(2))}</td>
                            </tr>
                        ))
                        )}
                    </tbody>
                </table>
            </div>
            <div className={style.shopping_details_boxes_lists}>
                <div>
                    <h3>Order Summary</h3>
                </div>
                <div>
                {orderNo ? (
                <ul className={style.shopping_details_prices_lists}>
                    <li>Subtotal: <span>{Number(OrderData?.subTotal || 0).toFixed(2)} SAR</span></li>
                    <li>Discount: <span>{Number(OrderData?.discountPrice || 0).toFixed(2)} SAR</span></li>
                    <li>Shipping Cost: <span>{Number(OrderData?.shippingCost || 0).toFixed(2)} SAR</span></li>
                    <li>VAT: <span>{Number(OrderData?.vat || 0).toFixed(2)} SAR</span></li>
                    <p>Total: <span>{Number(OrderData?.total || 0).toFixed(2)} SAR</span></p>
                </ul>
                ) : (
                <ul className={style.shopping_details_prices_lists}>
                    <li>Subtotal: <span>{Number(OrderresponseData?.totalProductPrice?.subTotal || 0).toFixed(2)} SAR</span></li>
                    <li>Discount: <span>{Number(OrderresponseData?.totalProductPrice?.discountPrice || 0).toFixed(2)} SAR</span></li>
                    <li>Shipping Cost: <span>{Number(OrderresponseData?.totalProductPrice?.shippingCost || 0).toFixed(2)} SAR</span></li>
                    <li>VAT: <span>{Number(OrderresponseData?.totalProductPrice?.vat || 0).toFixed(2)} SAR</span></li>
                    <p>Total: <span>{Number(OrderresponseData?.totalProductPrice?.total || 0).toFixed(2)} SAR</span></p>
                </ul>
                )}
          </div>
        </div>
            <div className={style.signature_wrap_box}>
                <div class="flex">
                    <div className={style.sigbox} id="">
                        <input name="signature" type="text" placeholder="" />
                        <p>Authorized Sign</p>
                    </div>
                </div>
                <div class="flex">
                    <div className={style.sigbox} id="">
                        <input name="signature" type="text" placeholder="" />
                        <p> Client Sign</p>
                    </div>
                </div>
            </div>
         
        </div>
    )
}
