import React, { useEffect, useState } from 'react';
import './css.css'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {IMG_URL} from '../../../config/config'
import cardImage from "../../../assets/images/productPage/productCard/cardImage.png";
import style from "./subcategories.modules.css";
import { Widgets } from '@mui/icons-material';
import {capitalizeWords} from '../../../utils/commonFunctions'
export default function SubCategoriesCard({ productCount = null, subCategoryId, categoryId, title = "", imageURL = "", subMenu }) {
   
    const navigate = useNavigate();
    const goToPosts = (categoryId, subCategoryId, subMenu, event) => {
        event.preventDefault()
        if (subMenu) {

            navigate({
                pathname: '/product',
                search: `?categoryId=${subMenu.categoryId}&subCategoryId[0]=${subMenu._id}`,
            });
            // window.location.reload()
        } else {

          
            let Querystring = `?categoryId=${categoryId}&subCategoryId[0]=${subCategoryId}`
            
            navigate({
                pathname: '/product',
                search: Querystring
            });
        }
    }
    const { t } = useTranslation();
    // const imageBaseURL = `https://admin-anycarpart.go-demo.com/Upload/subcategory_image`;
    const imageBaseURL = `http://localhost:3003/`;

    
    const [imageError, setImageError] = useState(false);

	const handleImageError = () => {
		setImageError(true);
	};
    return (
        // <Link to={{ pathname: `/product/?hjj=jhvhv` }}>
        <div onClick={(event) => { goToPosts(categoryId, subCategoryId, subMenu, event) }} className="container-fluid bg-trasparent my-4 p-1" style={{ position: "relative" }}  >

            <div className="col hp">


                <div className=" shadow-sm">
                    {/* <img src={imageURL ?`${imageBaseURL}/${imageURL}`: `/assets/images/break.png`} className="card-img-top" alt="product.productTitle" /> */}
                    {/* <img
                        // src={`${imageBaseURL}${getImages(title)[0]}` !== "https://admin-anycarpart.go-demo.com/Upload/subcategory_image/s" ? `${imageBaseURL}/${imageURL[0]}` : "https://scontent.fdel27-2.fna.fbcdn.net/v/t1.6435-9/64407960_623594441456227_306894233845891072_n.png?stp=dst-png_s350x350&_nc_cat=105&ccb=1-7&_nc_sid=947906&_nc_ohc=H8Chr0eWiN0AX_L4vdC&_nc_ht=scontent.fdel27-2.fna&oh=00_AfBvWX3raPI1MzJUzHjBwSP_A7Muxzl5QT2xR0J4gi4y_w&oe=655EEC53"}
                        src={`${imageBaseURL}${subCatImgs[0]}`}
                        className="card-img-top"
                        alt="product.productTitle"
                    /> */}

                    <img
                        src={imageURL ? IMG_URL+imageURL : "../../../assets/images/noimg-png-output.png"}
                        className="card-img-top"
                        style={{objectFit:'contain'}}
                        alt="Product Image"
                        onError={(e) => {
                            e.target.src = "../../../assets/images/noimg-png-output.png";
                        }}
                    />

                    <div>
                        <h5 style={{ textAlign: "center" }} className={style.card_heading}>
                        {capitalizeWords(title)}
                            <span> {productCount ? productCount : ``}</span>
                        </h5>

                    </div>
                </div>
            </div>

        </div>

        // </Link>
    )
}
